import {
  AdventureShirtLarge,
  ClassicWoolPeacoat,
  DuffelBag,
  GlobetrotterWatch,
  HamiltonWatch,
  JacksonLeatherBriefcase,
  LeatherBag,
  MotorcycleJacket,
  OutbackHat,
  SafariHat,
  WoolCoat,
  // VintageCanvasMessengerBag,
  TweedFlatCap,
  GreenFlannelShirt,
  TrenchWatch,
} from "../images/products/ProductImages";

const ProductData = [
  {
    id: "performance-wool-adventure-shirt",
    title: "Performance Wool Adventure Shirt",
    inStock: true,
    inStorePickup: true,
    price: 119.98,
    description:
      "The Performance Wool Adventure long-sleeve shirt looks and feels relaxed, but with UPF 50+ sun protection and ventilating grommets at critical locations, it also takes care of business on the trail. So go out and explore that beautiful world of ours.",
    image: AdventureShirtLarge,
    categories: "shirts",
    rating: 4.1,
    reviews: [
      {
        name: "Joe K",
        rating: 5,
        review:
          "In early April I wore my Savanna Trails short on hike in Grasslands National Park in Saskatchewan. Weather was cool, windy with some precipitation. Most of the time I wore a base layer and this as my out layer. Outstanding performance.",
      },
      {
        name: "Agawe",
        rating: 5,
        review:
          "Awesome shirt for every day wear or a outdoor excursion. Solid material, well made and i love the vents above the shoulders.",
      },
      {
        name: "Harry L",
        rating: 4,
        review:
          "I bought this mostly for the dry parts of the American Southwest as the ripstop fabric allows sweat to dry in a cooling fashion. For hot days this is almost ideal, but if the night is cold, this shirt needs insulation (like any lighter weight shirt would). Buttons and rolled up cuffs allow temperature adjustment when it heats up. This being my second shirt, I would like some longer lasting material (nylon) where a pack touches as those portions show a little fraying... will likely need to add myself via sewing or iron-on patches.",
      },
      {
        name: "DWhite",
        rating: 3.5,
        review:
          "I really like this shirt, BUT after about 5 uses, the tread in 3 spots has started pilling and the stitching as started to run. I think a shirt at this price point shouldn't have treads running after only a few uses. I've had a few of these shirts for 5+ years and have worn them more times than I can count and they've held up great, this one not so much. I'm going to return it and get another as I'm hoping this was just an anomaly.",
      },
      {
        name: "	Alaskadude",
        rating: 5,
        review:
          "I wore this shirt for 18 days strait on the Grand Canyon during a rafting trip and it was perfect. If I could only have one shirt to wear for an extended trip it would be this one.",
      },
    ],
  },
  {
    id: "hamilton-spirit-of-liberty-classic-watch",
    title: "Hamilton Spirit of Liberty Classic Watch",
    inStock: true,
    inStorePickup: true,
    price: 549.99,
    description:
      "The intrepid explorer can do no wrong with this sapphire-faced, exquisite timepiece.  Sleek, elegant, accurate, rugged--it's both subtle and makes a statement at the same time.  With silver accents, titanium casing, and reclaimed leather band.",
    image: HamiltonWatch,
    categories: "best-sellers, watches",
    rating: 4.9,
    reviews: [
      {
        name: "sir arthur",
        rating: 5,
        review:
          "This watch is amazing for the price!!! I was looking for a watch than can be worn both day-to-day and to semi-formal/formal events and this one fits the bill. Instantly was impressed by the nice presentation of the box that the watch came in and sizing to my wrist was really easy with the included tool! I have not noticed any wear or tear as of yet and I work in a hands on environment. I would recommend this to anyone who wants to look classy, but like me does not have the money to actually be classy. The movement of the watch is smooth, and stays accurate once the time is set.",
      },
      {
        name: "Tyler",
        rating: 5,
        review:
          "Really great product. Can't beat its price and quality. I've had watches 10 times its price that felt much cheaper. This watch is elegant and perfect for everyday use. Plus the packaging is exceptional. Before I bought this I was nervous about the strap being hard to configure based on other reviews, but I found it to be quite obvious.",
      },
    ],
  },
  {
    id: "globetrotter-watch",
    title: "Globetrotter Watch",
    inStock: true,
    inStorePickup: true,
    price: 599.99,
    description:
      "Tell them the tales of your travels and show them how you chronicled them with this smart timepiece from Forsining.  Now only will they see how you kept perfect time, but you can also show them where things occurred on hemisphere maps on the dial.  Look like an explorer of yore!",
    image: GlobetrotterWatch,
    categories: "watches",
    rating: 4.1,
    reviews: [
      {
        name: "Toby",
        rating: 5,
        review:
          "It's a nice, slim watch with a large face that doesn't cost a lot. Very practical and actually gets noticed from time to time because it doesn't look like the standard Fossil or Seiko. It keeps accurate time and has not been damaged by the occasional bump (I work in an office, not sure if you could wear it on a construction site).",
      },
      {
        name: "Inspector Red",
        rating: 4,
        review:
          "Upon receiving this beautiful watch it found a new home on my son's arm. It is yet elegant but casual. It's sleek band is just that, sleek and cool. Anyone At any age is able to tell time with this watch at any time of the day and for any age appropriate. I suggest this watch for a great upcoming birthday gift or for the upcoming holiday Christmas. This watch is definitely one that will put a smile on that someone specials face.",
      },
    ],
  },
  {
    id: "italian-motorcycle-jacket",
    title: "Italian Motorcycle Jacket",
    inStock: true,
    inStorePickup: false,
    price: 349.49,
    description:
      "No need to say Scussi when you're on your Moto Guzzi.  This jacket says you're all zipped up and ready for business.  The leather's as soft as a baby's bottom--as it should be since it is sourced exclusively from New Zealand milk-fed baby sheep skin.  (t-shirt sold separately)",
    image: MotorcycleJacket,
    categories: "coats-and-jackets",
    rating: 3.8,
    reviews: [
      {
        name: "Gorgonzola",
        rating: 4,
        review:
          "The pics look nicer than the jacket was. Not as soft as I was expecting. Very light weight. I don’t think this would keep you warm. The biggest problem I had was the overpowering smell right out of the bag. I let it hang in my garage for a couple of days and the smell never went away. I couldn’t wear this smelling like it did. I don’t know if it was from the tanning process, but it was a deal killer for me. Additionally, the size runs a little small.",
      },
      {
        name: "Guest Custoemr",
        rating: 3,
        review:
          "Overall, I think the jacket is pretty decent for its price. Time will tell more on the leather quality. I could be a perfectionist but small touch like the pocket lining could have made this jacket feel much higher quality. I don't think polyester would break the margin, so I'm wondering why they are using such weird lining. I'm looking to see if I can replace the pocket lining.",
      },
    ],
  },
  {
    id: "leather-bike-messenger-bag",
    title: "Leather Bike Messenger Bag",
    inStock: true,
    inStorePickup: false,
    price: 185.49,
    description:
      "You want to look hip but aren't into being sweaty and wearing bike shorts?  Then sling on one of these handmade, Italian leather bike messenger bags.  Show the world you are too cool for school and sophisticated to boot.",
    image: LeatherBag,
    categories: "best-sellers, bags, leather",
    rating: 3.5,
    reviews: [
      {
        name: "pug love",
        rating: 3,
        review: "Great bag. It makes me faster.",
      },
      {
        name: "Swim2001",
        rating: 5,
        review:
          "I have been looking for a bag like this for a long while. Perfect size for me (not too big, as I wanted it for my tablet and not my laptop), zippers to prevent things from falling out, lots of pockets. When the bag arrived, I was excited to see that it looked like a good quality product. However, after a short two weeks of very light use (I only carried a tablet and not a laptop), the loop where the strap is attached to the bag broke while I was on a work trip (major inconvenience, but this is a separate story). Upon closer inspection, I saw that although the rest of the bag is good quality, the loops on both sides are paper thin and flimsy. I contemplated ordering a new bag , exact copy, but I am afraid that this was not manufacturing defect but a design choice (usage of weak/cheap material for the loops), so every bag I buy from this manufacturer will be the same. A bag is as good as its weakest link, and for this reason I am sad to say this is a terrible product.",
      },
      {
        name: "patriots2014",
        rating: 4.5,
        review: "Wondeful bag. Such a perfect size and so so so sturdy.",
      },
      {
        name: "Brenda Stark",
        rating: 3,
        review:
          "I would give this review 5 stars if the velcro was better. After having the bag for a few months, the velcro has begun to wear down. It wouldn't be a huge issue, but when you pick up the bag buy the handle, it pulls up on the flap, thus ripping the flap from the velcro. I really do love everything else about this bag. The quality of the material is good, I love the zippers, and the amount of compartments it has. If you're going to want to use this bag for years, I recommend buying some spare velcro.",
      },
    ],
  },
  {
    id: "leather-duffel-gymnasium-bag",
    title: "Leather Duffel Gymnasium Bag",
    inStock: true,
    inStorePickup: false,
    price: 249.99,
    description:
      "Tired of velcro and nylon and spandex?  Our vintage leather duffel gymnasium bag shows the world you are serious about your workout, and can still look classy.  Includes special compartment for two kettle balls.",
    image: DuffelBag,
    categories: "bags, leather",
    rating: 3.5,
    reviews: [
      {
        name: "Kayu",
        rating: 4,
        review:
          "Got this as a present for my husband and he loves it. The look, quality inside and out is really nice. It reminds my hubby of the Indiana Jones kind of messenger bag. My only concern is that the inside sleeve for the laptop is just a half an inch too small and we have to maneuver in many ways to get the laptop in. He has a 14 inch lenovo and the product title said that this bag would fit a 14 inch laptop but I am not completely sure of that. Anyway, we are still keeping it because its really cool and we are hoping that the sleeve will have a little give over time and that he will be able to slip the laptop in and out smoothly. If you have a laptop smaller than a 14 inch then get this bag. It has many pockets and plently of room for books, laptop, charger etc.",
      },
      {
        name: "Joe in WV",
        rating: 4,
        review:
          "I really liked this bag at first... great construction, nice leather and seems durable, but it doesn't really hold a lot. Once you put a few items in it such as papers, a book, laptop and misc. it's pretty bulky and you cannot get it zipped up. I kept it only because I got it for a really good price and didn't want to pay to send it back but I wish it was a little wider. Positive point.. it keeps me from overloading it since it doesn't hold much. For everyday essentials to go back and fourth to work it fits the purpose.",
      },
      {
        name: "Ydgridds",
        rating: 5,
        review:
          "I love that the messenger bag has functional compartments and is quality leather. It’s light and has great shoulder support. I’ve received so many compliments and am very satisfied with my purchase.",
      },
      {
        name: "Andri L",
        rating: 4,
        review:
          "Needed something professional that went with both blues and blacks for my husband’s computer bag. Great bag and beautifully made for the price.",
      },
    ],
  },
  {
    id: "leather-outback-hat",
    title: "Leather Outback Hat",
    inStock: true,
    inStorePickup: false,
    price: 124.99,
    description:
      "You'll feel like wrestling some crocodiles in your rugged, weathered Outback hat.  Stylish and durable.  Crafted from the finest dingo leather.",
    image: OutbackHat,
    categories: "leather, hats",
    rating: 4.0,
    reviews: [
      {
        name: "rfkl1969",
        rating: 5,
        review:
          "pulled out of box..nice...I use to be 7 3/8..suppose still am.. ordered a large... fits perfect...so comfortable dont want to take it off...",
      },
      {
        name: "Browning Shaw",
        rating: 4.2,
        review:
          "Love this hat bought a brown one at local fair and liked so much bought the black one here,my every day hat wear it whenever go out",
      },
      {
        name: "kokakola",
        rating: 3.8,
        review:
          "Believe me, this runs large. You'll probably need to order a size down but it is a very handsome and well made hat. My 20 yr old son really liked it when it came in but it spun on his head. He ordered the medium although his head circumference is actually somewhere btwn a med and a lg. I recommended he get the med and it's still too big...he has a very normal, avg size head! So he's sending in for a small and hopefully it will fit and not be too small. I'll write another review when the small comes in.",
      },
      {
        name: "ChinaKings",
        rating: 5,
        review:
          "When I got it the person had shoved it in my mailbox, it looked like it was going to be ruined but when I took it out of the box it was fine and the sizing chart was spot on it fit my head perfectly. Nothing wrong with it at all",
      },
      {
        name: "Dan",
        rating: 2,
        review:
          "You should immediately protect the leather with saddle soap or something else that seals it. I didn't do it right away, and two droplets of water got on the top causing the leather to bubble slightly.",
      },
      {
        name: "Guest Customer",
        rating: 5,
        review:
          "I have a fairly large head (24 3/8\") so buying a hat is always a crapshoot. I got the XXL size and it fit me snugly, but not uncomfortably. This hat is exceptionally high quality and I've worn it every day since it arrived. Shipping was much faster than I expected. I don't know how Conner Hats can sell these hats this inexpensively and turn a profit. A hand-made all leather hat of this quality would be a steal at twice the price.",
      },
    ],
  },
  {
    id: "mens-civil-war-coat",
    title: "Men's Civil War Coat",
    inStock: true,
    inStorePickup: false,
    price: 325.05,
    description:
      "This Civil War-inspired steel-gray wool coat has double-lining for warmth and sharp lines for style.  Whether chopping wood or going to the club, this coat demands attention and will help you get what you need to get done!  Woven with Himalayan yak hair and lined with Indian cotton.",
    image: WoolCoat,
    categories: "coats-and-jackets",
    rating: 4.2,
    reviews: [
      {
        name: "Westie Love",
        rating: 4.5,
        review:
          "I ordered this online after carefully checking the sizing chart I wasn’t sure it would fit me but it is perfect and I’m very happy.",
      },
      {
        name: "kku5075",
        rating: 4,
        review:
          "5'6\" 125lbs and slim - I bought a size XS, natural (cream color) and it fits perfectly. Snug but not too tight, and still gives me some flexibility of movement. Pairs really well with different shades of colors. Received multiple compliments on it. Quality is sturdy and should last a very long time. Recommended!",
      },
      {
        name: "LA Artist",
        rating: 5,
        review:
          "Bought this item using Rewards Cash last week and have worn twice since, cold weather be damned! Fits true to size, well made, sturdy and just the right weight. Versatile color. I’ve shopped at the Quinn & Murray since 1975 and this is one of the best of the best. Thank you!",
      },
    ],
  },
  {
    id: "panama-safari-hat",
    title: "Panama Safari Hat",
    inStock: true,
    inStorePickup: true,
    price: 95.49,
    description:
      "Whether braving the steaming Central American jungle or sipping on a pina colada, you are always ready for adventure.  Look cool and keep cool in this traditional straw Panama hat.",
    image: SafariHat,
    categories: "best-sellers, hats",
    rating: 5.0,
    reviews: [
      {
        name: "Shaun",
        rating: 5,
        review:
          "Great safari hat with removal flap and concealed mosquito net, my girlfriend and I plan to use this hat when we go to Africa",
      },
      {
        name: "ariel ashley",
        rating: 5,
        review:
          "I’m working on a trail crew in the 100 mile wilderness this summer and knew from previous adventures in central Ontario that I would need a headnet to combat the black flies and mosquitoes. I wanted it to have a draw string bottom to keep the bugs from crawling up into it and found this. I got it today and it’s even better than the pictures lead to believe. The net easily folded into the top of the hat out of the way and the detachable sun shield will be a tremendous help when working in burning summer sun. The material feels like very good quality and I absolutely love it. I’ll definitely be looking into their gloves now! Awesome find!",
      },
      {
        name: "Guest Customer",
        rating: 5,
        review:
          "The hat was for my boyfriend who likes to go fishing at dusk. The mosquitos are swarming this year, so we got this hat for him. He is not a hat person, but likes wearing it so much, he will also be wearing it without the net when he is out in the sun.",
      },
      {
        name: "Gerry",
        rating: 5,
        review:
          "I received this hat a few days ago, very good quality, very comfortable to wear, very suitable for summer fishing to catch crabs, the front can cover the face, prevent mosquito bites, sand and dust! The back can cover the neck and prevent sunburn! Very practical hat!",
      },
      { name: "Andy", rating: 5, review: "Good quality. Good price." },
      {
        name: "Kat",
        rating: 5,
        review:
          "Items from this seller arrived on time without delay. The item appears to be made of quality material and good craftsmanship. Material is lightweight but I am not sure if it is water repellent. I wish there were sizing options available for this product. I loved the hat but had to return because it did not fit a larger head.",
      },
      {
        name: "LindayHop",
        rating: 5,
        review:
          "This was a gift well received...i orginally bought it for the Arizona sun ..they were planning a trip but she was so happy to use it in the garden.",
      },
    ],
  },
  {
    id: "jackson-leather-briefcase",
    title: "Jackson Leather Briefcase",
    inStock: false,
    inStorePickup: true,
    price: 349.99,
    description:
      "The pinacle of taste and luxury in a single bag. The titanium-strength leather can not be scratched or torn by any tool known to man. Look stylish while keeping your business safe.",
    image: JacksonLeatherBriefcase,
    categories: "bags, leather",
    rating: 4.1,
    reviews: [
      {
        name: "BagGod",
        rating: 5,
        review:
          "The single greatest purchase of my life. I met my wife, got my job and grew the business all because of this bag!",
      },
      {
        name: "Jeff B",
        rating: 4,
        review: "Great construction, nice leather and seems durable. I tried to cut it with a knife and it stood up."
      },
      {
        name: "alberto78",
        rating: 4.3,
        review:
          "Professionals are carrying less computer these days and more electronics like Ipads Ipods, storage devices, wireless mouse etc.. I carry a Sony 14 inch convertible, it did not come with mouse or dvd and I have an external TB hard drive and many small drives. This bag does it all to fit my little small office when I need to be on the road. I stock this puppy like stocking the bar and I am all set. I had an HP bag that was a real road warrior but she finally broke down after almost 20 years. If I get half the mileage out of this one, I am a happy camper. I saw a lot of the negative comments on this bag and I can't see much more you can ask from Samsonite. This is a great buy and I say if you are looking for a very nice middle of the road bag this is it.",
      },
    ],
  },
  // {
  //   id: "vintage-canvas-messenger-bag",
  //   title: "Vintage Canvas Messenger Bag",
  //   inStock: false,
  //   price: 149.99,
  //   description:
  //     "Pure Cotton made high destiny washed canvas with delicate sewn craftsmanship, smooth and very durable.",
  //   image: VintageCanvasMessengerBag,
  //   categories: "bags, canvas",
  //   rating: 4.5,
  //   reviews: [
  //     {
  //       name: "BagGod",
  //       rating: 5,
  //       review:
  //         "This is a great looking bag for sure. I had so many comments and sometimes people keep staring at my bag out of admiration. I caught out few time under the rain but nothing major has affected my stuff inside the bag. The fabric is a sort of heavy and solid to let water get in easily, though!"
  //     },
  //     {
  //       name: "John D",
  //       rating: 4,
  //       review:
  //         "Great construction, nice canvas material and seems durable. Love the Vintage look."
  //     },
  //     {
  //       name: "rodrigo B",
  //       rating: 4.3,
  //       review:
  //         "The bag is true to the color posted. It is not green, very practical and durable."
  //     }
  //   ]
  // },
  {
    id: "classic-wool-peacoat",
    title: "Classic Wool Peacoat",
    inStock: false,
    inStorePickup: false,
    price: 219.99,
    description:
      "A classic with strong nautical history, the peacoat continues to charm us throughout the ages. This double breasted deep navy shade proves that point. Wear it open, close it up for warmth or pop the collar. However you style it, it always feels right. ",
    image: ClassicWoolPeacoat,
    categories: "coats-and-jackets, best-sellers",
    rating: 3.2,
    reviews: [
      {
        name: "Jay",
        rating: 1,
        review:
          "I bought this coat less than 2 months ago;. Within the first 2 weeks two seams of the inner lining were coming apart. It has been less than 2 months since that I've had this, and now almost every seam of the lining is letting loose. I would throw this coat away if I had another winter coat.",
      },
      {
        name: "Barry",
        rating: 4,
        review: "Great construction, nice wool material and seems durable. Love the Vintage look."
      },
      {
        name: "Maddie",
        rating: 4.3,
        review: "The coat is true to the color posted. Very practical and durable."
      }
    ]
  },
  {
    id: "tweed-flat-cap",
    title: "Tweed Flat Cap",
    inStock: false,
    inStorePickup: true,
    price: 59.99,
    description:
      "A classic, timeless Ivy League style flat cap. Model sewn with original Harris Tweed. Inside there is a quilted lining made of breathable cotton. It also has 2 thin, decorative, leather belts with buckles on sides. Thanks to used materials cap offers an excellent wearing comfort and good breathability. Harris Tweed cloths are second to none when it comes to wool fabrics. Good choice for winter and passing seasons - warm, breathable and elegant. Available in large xl and xxl sizes. Precise handmade craftsmanship from Poland.",
    image: TweedFlatCap,
    categories: "hats",
    rating: 4.2,
    reviews: [
      {
        name: "Teebone",
        rating: 4,
        review:
          "First of all you can tell this is a really well made cap. Nice fit and feel. I ordered the gray salt and pepper and when I put it on I loved it...until my wife said 'you got a brown cap?' I told her it was gray. We went back and forth and the more I looked at it the more I did in fact see a lot of brown in it. You don't notice it much from afar (or in the stock photos) and many still see gray but every time I look I see the brown (see my photos). That was the only thing keeping it from 5 stars and truth be told it could like the viral dress color thing - some see one color and others another.",
      },
      {
        name: "Kathleen",
        rating: 4,
        review:
          "This is my husband's 4th Hanna Patchwork Cap. When he bought his first cap, about 43 years ago, it wasn't hard to find the Brights and that cap, despite the Dry Cleaner breaking the bill in the last cleaning job, is still his favorite.",
      },
      {
        name: "Albertina",
        rating: 4.3,
        review:
          "This was a perfect replacement for my well worn previous one. It's exactly the same as the one that I bought almost 20 years ago (except for the price) and its quality is excellent. A great fall and winter cap in New England, even in snow and light rain -- warm and handsome. I even had a guy compliment me on its look during a subway commute to work. ",
      },
    ],
  },
  {
    id: "classic-flannel-shirt",
    title: "Classic Flannel Shirt",
    inStock: false,
    inStorePickup: false,
    price: 89.99,
    description:
      "Plaid shirt in soft cotton flannel with a turn-down collar. Classic button placket, two open chest pockets, and long sleeves with adjustable buttoning at cuffs. Rounded hem.",
    image: GreenFlannelShirt,
    categories: "shirts",
    rating: 4.5,
    reviews: [
      {
        name: "caclark98",
        rating: 5,
        review:
          "Okay so if you don’t really read full descriptions like me you’ll assume this is normal flannel material (thin-ish) but this is not that all. I really wanted it for summer but this is like a fall warm flannel almost like a blanket thickness. But even though it wasn’t what I was excepting it’s actually super great quality for the price. ",
      },
      {
        name: "FED",
        rating: 5,
        review:
          "I am not a plaid guy however, this shirt is the beans. Made in Bangladesh, the sewing is the best I have seen in a shirt in years. The fabric is soft next to the skin and not scratchy like the wool shirts our moms used to make us wear. The collar, cuffs and placket are lined. No uncomfortable tags. This shirt is warm and I am wearing it almost like a shirt/jacket with a long sleeve T underneath during our January sub freezing weather. A very handsome, well made shirt...remember, it's fleece...the girls will want to wear it so be prepared. Recommended without reservation.",
      },
      {
        name: "Kyle Anders",
        rating: 4,
        review:
          "If you normally wear a size XL shirt, like I do, then get the large. I got the XL and it turned out to be too big - returned it - got the large and it fit perfect. Decent quality little flannel; it's comfy, warm, and is made of pretty thick fabric.",
      },
    ],
  },
  {
    id: "replica-trench-watch",
    title: "Replica Trench Watch",
    inStock: false,
    inStorePickup: true,
    price: 399.99,
    description:
      "Case with Fixed Lugs (as they were in WW1). Beautifully Clear White Dial with Both 12 and 24 Hour Chapters and the Red 12. Stunning Silver Onion Crown, Clear Luminous Cathedral Hands, Steel Case and Classic Brown Leather Band (Also comes with a Black Band).",
    image: TrenchWatch,
    categories: "watches",
    rating: 4.8,
    reviews: [
      {
        name: "VintageWatchCollector",
        rating: 5,
        review: "This is a very special watch. Same as the famous Ingersol trench watch."
      },
      {
        name: "John D",
        rating: 5,
        review:
          "About the watch, knew I had to have it the first time I saw it. It just exudes old world charm and resembles a modified pocket watch from the beginning of the century but within ticks an accurate quartz movement. It looks like it has luminous hands and dial numerals but it really doesn't. It's totally ok though, press that crown and it glows with Timex Indiglo which is easier to read in the dark than any luminous paint. I swapped out the original strap for an olive coloured nato strap and as you can see from the picture attached, it totally rocks it. This is indeed a rare beauty from Timex. Glad I bought it and thank you Luxury Bazaar for making it happen.",
      },
      {
        name: "rodrigo B",
        rating: 4.3,
        review:
          "Is this a watch from the Twenties or the Forties? I really like it and I hardly realize I'm wearing it. The band is thin, yet there are three keepers for the band. As a long time watch collector I recommend this one for time keeping and comfort. It is inexpensive and keeps great time. It has a really good setting mechanism which allows you to set it accurately with a time signal. I also like its style. Older clocks and watches are part of Americana. Finally, the band is under the watch case so that your skin is not stuck to the metal case. I like that as well.",
      },
    ],
  },
];

export default ProductData;
