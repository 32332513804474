import React from "react";
import PaypalLogoIcon from "../../images/logos/paypal-logo.png";

import axios from "axios";

const PaypalButton = props => {
  const httpClient = axios.create();
  httpClient.defaults.timeout = 500;

  function handleClick(event) {
    if (event) event.preventDefault();

    httpClient
      .get("https://api.paypal.com/checkout")
      .then(function(response) {
        // It will never succeed
      })
      .catch(function(error) {
        // It will always error
        console.log(error);
      });
  }
  return (
    <div className="row justify-content-end">
      <div className="col col-md-10 col-lg-10">&nbsp;</div>
      <div className="col col-md-2 col-lg-2">
        {props.active ? (
          <button
            id="paypalBtn"
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={handleClick}
          >
            <img
              id="paypalLogoImage"
              src={PaypalLogoIcon}
              alt="PayPal checkout button"
            />
          </button>
        ) : (
          <button
            id="paypalBtn"
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={handleClick}
            disabled
          >
            <img
              id="paypalLogoImage"
              src={PaypalLogoIcon}
              alt="PayPal checkout button"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default PaypalButton;
