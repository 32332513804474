import {
  AdventureShirtMedium,
  DuffelBag,
  // GlobetrotterWatch,
  HamiltonWatch,
  LeatherBag,
  // MotorcycleJacket,
  OutbackHat,
  SafariHat,
  WoolCoat
} from "../images/products/ProductImages";

const CatalogData = [
  {
    id: "bags",
    title: "Bags",
    link: "/catalog/bags",
    image: LeatherBag
  },
  {
    id: "best-sellers",
    title: "Best Sellers",
    link: "/catalog/best-sellers",
    image: SafariHat
  },
  {
    id: "coats-and-jackets",
    title: "Coats and Jackets",
    link: "/catalog/coats-and-jackets",
    image: WoolCoat
  },
  {
    id: "hats",
    title: "Hats",
    link: "/catalog/hats",
    image: OutbackHat
  },
  {
    id: "leather",
    title: "Leather",
    link: "/catalog/leather",
    image: DuffelBag
  },
  {
    id: "shirts",
    title: "Shirts",
    link: "/catalog/shirts",
    image: AdventureShirtMedium
  },
  {
    id: "watches",
    title: "Watches",
    link: "/catalog/watches",
    image: HamiltonWatch
  }
];

export default CatalogData;
