import { useEffect } from "react";
import { withRouter } from "react-router-dom";

// Import google analytics utility
import ReactGA from "react-ga";
ReactGA.initialize("UA-148424573-1");

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("navLogo").scrollIntoView();

    // console.log("scrollToTop pathname:", pathname);
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
