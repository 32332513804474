import React, { useState, useContext, useEffect } from "react";
import Spinner from "react-svg-spinner";

import { CheckoutContext } from "../../state/CheckoutContext";
import CheckoutUtil from "../../state/CheckoutUtil";

import PageTitle from "../PageTitle";

import RewardsBanner from "../RewardsBanner";

const Payment = ({ history }) => {
  const checkoutContext = useContext(CheckoutContext);
  // console.log(checkoutContext.shippingInfo);
  // console.log(checkoutContext.paymentInfo);
  const visible = "d-block";
  const hidden = "d-none";

  // const [firstName, setFirstName] = useState(
  //   checkoutContext.shippingInfo.firstName
  // );
  // const [lastName, setLastName] = useState(
  //   checkoutContext.shippingInfo.lastName
  // );
  // const [address1, setAddress1] = useState(
  //   checkoutContext.shippingInfo.address1
  // );
  // const [city, setCity] = useState(checkoutContext.shippingInfo.city);
  // const [state, setState] = useState(checkoutContext.shippingInfo.state);
  // const [zip, setZip] = useState(checkoutContext.shippingInfo.zip);

  const [payName, setPayName] = useState(checkoutContext.paymentInfo.name);
  const [payNumber, setPayNumber] = useState(
    checkoutContext.paymentInfo.number
  );
  const [payExpiration, setPayExpiration] = useState(
    checkoutContext.paymentInfo.expiration
  );
  const [payCvv, setPayCvv] = useState(checkoutContext.paymentInfo.cvv);

  const [spinnerVisible, setSpinnerVisible] = useState(hidden);

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const handleSubmit = event => {
    if (event) event.preventDefault();

    const form = event.currentTarget;
    if (form && form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    }

    setSpinnerVisible(visible);
    let timeout = randomIntFromInterval(0, 5);

    setTimeout(function() {
      if (!!payNumber && !payNumber.includes("0000")) {
        setSpinnerVisible(hidden);
        history.push("/checkout/review");
      }
    }, timeout);
  };

  const pageTitle = "Payment Details";
  useEffect(() => {
    document.title = `${pageTitle}`;

    // let tmpBillingInfo = {
    //   firstName: firstName,
    //   lastName: lastName,
    //   address1: address1,
    //   city: city,
    //   state: state,
    //   zip: zip
    // };
    // checkoutContext.billingInfo = tmpBillingInfo;
    // console.log("Billing: ", checkoutContext.billingInfo);

    let tmpPaymentInfo = {
      name: payName,
      number: payNumber,
      expiration: payExpiration,
      cvv: payCvv
    };
    checkoutContext.paymentInfo = tmpPaymentInfo;
    // console.log("paymentInfo",checkoutContext.paymentInfo);

    CheckoutUtil.update(checkoutContext);
  });

  return (
    <div id="paymentPage" className="container">
      <RewardsBanner />

      <PageTitle title={pageTitle} />

      <form className="needs-validation" noValidate onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            {/* Start Payment Info */}
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="ccName">Name on card</label>
                <input
                  type="text"
                  className="form-control"
                  id="ccName"
                  placeholder="John Smith"
                  required
                  defaultValue={payName}
                  onBlur={event => {
                    setPayName(event.target.value);
                  }}
                />
                <small className="text-muted">
                  Full name as displayed on card
                </small>
                <div className="invalid-feedback">Name on card is required</div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="ccNumber">Credit card number</label>
                <input
                  type="text"
                  className="form-control"
                  id="ccNumber"
                  maxLength="19"
                  required
                  autoComplete="off"
                  placeholder="4111 1111 1111 1111"
                  defaultValue={payNumber}
                  onBlur={event => {
                    setPayNumber(event.target.value);
                  }}
                />
                <div className="invalid-feedback">
                  Credit card number is required
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <label htmlFor="ccExpiration">Expiration</label>
                <input
                  type="text"
                  className="form-control"
                  id="ccExpiration"
                  maxLength="5"
                  autoComplete="off"
                  placeholder="01/21"
                  required
                  defaultValue={payExpiration}
                  onBlur={event => {
                    setPayExpiration(event.target.value);
                  }}
                />
                <div className="invalid-feedback">Expiration date required</div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="cvv">CVV</label>
                <input
                  type="text"
                  className="form-control"
                  id="cvv"
                  maxLength="3"
                  autoComplete="off"
                  placeholder="000"
                  required
                  defaultValue={payCvv}
                  onBlur={event => {
                    setPayCvv(event.target.value);
                  }}
                />
                <div className="invalid-feedback">Security code required</div>
              </div>
            </div>

            <hr className="mb-4" />
            {/* End Form */}
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="col col-lg-10 text-right">
            <div id="spinner" className={spinnerVisible}>
              <Spinner size="64px" color="green" />
            </div>
          </div>
          <div className="col">
            <button
              id="continueBtn"
              type="submit"
              data-toggle="modal"
              data-target="#spinnerModal"
              className="btn btn-primary btn-lg"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Payment;
