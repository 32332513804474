import React from "react";

const PageTitle = props => {
  return (
    <div>
      <div className="row pageTitle">
        <div className="col">
          <h1>{props.title}</h1>
        </div>
      </div>
      <hr className="mb-4" />
    </div>
  );
};

export default PageTitle;
