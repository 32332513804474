import React, { useState, useContext, useEffect } from "react";

import { CheckoutContext } from "../../state/CheckoutContext";
import CheckoutUtil from "../../state/CheckoutUtil";
import PageTitle from "../PageTitle";
import RewardsBanner from "../RewardsBanner";

function Shipping({ history }) {
  const checkoutContext = useContext(CheckoutContext);
  // console.log("checkoutContext.shippingInfo: ", checkoutContext.shippingInfo);

  const [firstName, setFirstName] = useState(
    checkoutContext.shippingInfo.firstName
  );
  const [lastName, setLastName] = useState(
    checkoutContext.shippingInfo.lastName
  );
  const [address1, setAddress1] = useState(
    checkoutContext.shippingInfo.address1
  );
  const [city, setCity] = useState(checkoutContext.shippingInfo.city);
  const [state, setState] = useState(checkoutContext.shippingInfo.state);
  const [zip, setZip] = useState(checkoutContext.shippingInfo.zip);

  const pageTitle = "Shipping Details";
  useEffect(() => {
    document.title = `${pageTitle}`;

    let shipInfoTmp = {
      firstName: firstName,
      lastName: lastName,
      address1: address1,
      city: city,
      state: state,
      zip: zip
    };
    // console.log("shipInfoTmp:", shipInfoTmp);

    checkoutContext.shippingInfo = shipInfoTmp;

    if (
      !checkoutContext.paymentInfo.name ||
      checkoutContext.paymentInfo.name.length > 0
    ) {
      checkoutContext.paymentInfo.name = firstName + " " + lastName;
    }
  });

  const handleSubmit = event => {
    if (event) event.preventDefault();

    const form = event.currentTarget;
    if (form && form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    }

    CheckoutUtil.update(checkoutContext);

    history.push("/checkout/payment");
  };

  return (
    <div id="shippingPage" className="container">
      <RewardsBanner />

      <PageTitle title={pageTitle} />

      <form className="needs-validation" noValidate onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            {/* Start Form */}
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstName">First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  required
                  autoFocus
                  placeholder="John"
                  defaultValue={firstName}
                  onBlur={event => {
                    setFirstName(event.target.value);
                  }}
                />
                <div className="invalid-feedback">
                  Valid first name is required.
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastName">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  required
                  placeholder="Smith"
                  defaultValue={lastName}
                  onBlur={event => {
                    setLastName(event.target.value);
                  }}
                />
                <div className="invalid-feedback">
                  Valid last name is required.
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className="form-control"
                id="address"
                required
                placeholder="123 Main St"
                defaultValue={address1}
                onBlur={event => {
                  setAddress1(event.target.value);
                }}
              />
              <div className="invalid-feedback">
                Please enter your shipping address.
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 mb-3">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  required
                  placeholder="Springfield"
                  defaultValue={city}
                  onBlur={event => {
                    setCity(event.target.value);
                  }}
                />
                <div className="invalid-feedback">Please enter your city.</div>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  placeholder="IL"
                  defaultValue={state}
                  onBlur={event => {
                    setState(event.target.value);
                  }}
                />
                <div className="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="zip">Zip</label>
                <input
                  type="text"
                  className="form-control"
                  id="zip"
                  placeholder="10001"
                  defaultValue={zip}
                  onBlur={event => {
                    setZip(event.target.value);
                  }}
                />
                <div className="invalid-feedback">Zip code required.</div>
              </div>
            </div>
            {/* End Form */}
          </div>
        </div>

        <hr className="mb-4" />
        <div className="row justify-content-end">
          <div className="col col-lg-10 text-right">&nbsp;</div>
          <div className="col">
            <button
              id="continueBtn"
              type="submit"
              className="btn btn-primary btn-lg"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Shipping;
