import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";

import { CartContext } from "../../state/CartContext";
import CartUtil from "../../state/CartUtil";

const CartItemTableRow = props => {
  const cartContext = useContext(CartContext);
  // console.log("CartItemTableRow, edit:", props.edit);

  const renderQuantityInput = (edit, item) => {
    // console.log("renderQuantityInput, edit:", edit);

    const handleChange = value => {
      // console.log("changeInput value:", value);
      // console.log("changeInput item:", item);

      CartUtil.updateItem(cartContext, item, value);
    };

    if (edit) {
      return (
        <input
          id={`qty-${item.product.id}`}
          maxLength="2"
          size="3"
          className="form-control"
          defaultValue={item.quantity}
          onBlur={e => handleChange(e.target.value)}
        />
      );
    } else {
      return <span>{item.quantity}</span>;
    }
  };

  const renderRemoveIcon = (edit, index) => {
    // console.log("renderRemoveIcon, edit:", edit);

    const handleClick = i => {
      // console.log("removing", i);
      CartUtil.removeItem(cartContext, i);
    };

    if (edit) {
      return (
        <td>
          <Link className="removeItemBtn" to="/checkout/cart">
            <i className="fa fa-trash" onClick={() => handleClick(index)} />
          </Link>
        </td>
      );
    }
  };

  return CartUtil.getItems(cartContext).map((item, index) => (
    <tr key={item.product.id}>
      <td>
        <Link to={`/products/${item.product.title}`}>{item.product.title}</Link>
      </td>
      <td>${item.product.price}</td>
      <td>
        {renderQuantityInput(props.edit, item)}
        {/* <span>{item.quantity}</span> */}
      </td>
      <td>${item.itemTotal}</td>
      {renderRemoveIcon(props.edit, index)}
    </tr>
  ));
};

const CartItemList = props => {
  // const cartContext = useContext(CartContext);
  // console.log("cartContext", cartContext);
  // console.log("edit", props.edit);

  let bool = false;
  if (props.edit === "yes") {
    bool = true;
  }
  // console.log("bool", bool);

  const [allowEdit] = useState(bool);

  const renderRemoveColumnHeader = () => {
    if (allowEdit) {
      return <th scole="col">&nbsp;</th>;
    }
  };

  const renderUpdateButton = edit => {
    if (edit) {
      return (
        <tfoot>
          <tr>
            <td />
            <td />
            <td>
              <Link id="updateCartBtn" to="/checkout/cart">
                <button className="btn btn-light btn-sm">Update</button>
              </Link>
            </td>
            <td />
            <td />
          </tr>
        </tfoot>
      );
    }
  };

  return (
    <div className="row">
      <div className="col">
        <table className="table">
          <thead>
            <tr>
              <th className="col-3" scope="col">
                Product Name
              </th>
              <th className="col-2" scope="col">
                Price
              </th>
              <th className="col-1" scope="col">
                Quantity
              </th>
              <th className="col-2" scope="col">
                Total
              </th>
              {renderRemoveColumnHeader()}
            </tr>
          </thead>
          <tbody>
            <CartItemTableRow edit={allowEdit} />
          </tbody>
          {renderUpdateButton(allowEdit)}
        </table>
      </div>
    </div>
  );
};

export default withRouter(CartItemList);
