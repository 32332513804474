import React, { useState } from "react";

import StarRatings from "react-star-ratings";

const ProductReviews = props => {
  const [productReviews] = useState(props.reviews);

  return (
    <div id="productReviews" className="mt-5 mb-4">
      <div className="row">
        <div className="col">
          <h4 id="productReviewsHeader">Product Reviews</h4>
        </div>
      </div>
      {productReviews.map(review => (
        <div key={review.name}>
          <div className="row mt-1 align-items-end">
            <div className="col">
              <hr className="mb-1" />
              <span>
                <StarRatings
                  rating={review.rating}
                  starRatedColor="blue"
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  starSpacing="1px"
                />
              </span>
              <span className="ml-2 font-weight-bold">{review.name}</span>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col">{review.review}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductReviews;
