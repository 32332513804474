import React, { useState, useContext } from "react";
import { CartContext } from "../../state/CartContext";

import CartUtil from "../../state/CartUtil";

export default function PromoCodeSection() {
  const cartContext = useContext(CartContext);
  const zeroDiscount = (0.0).toFixed(2);

  const visible = "d-block";
  const hidden = "d-none";

  const [errorVisible, setErrorVisible] = useState(hidden);
  const [promoErrorMessage, setPromoErrorMessage] = useState("");
  const [promoCode, setPromoCode] = useState(cartContext.promoCode);
  const [promoDiscount, setPromoDiscount] = useState(cartContext.promoDiscount);

  // Hide the error message
  const hidePromoErrorMessage = () => {
    setErrorVisible(hidden);
    setPromoErrorMessage("");
  };

  // Show the error message and update the message text
  const showPromoErrorMessage = message => {
    setErrorVisible(visible);
    setPromoErrorMessage(message);
  };

  const promoClick = () => {
    //console.log("promoCode", promoCode);

    switch (promoCode.toLowerCase()) {
      case "":
        hidePromoErrorMessage();
        setPromoDiscount(zeroDiscount);
        CartUtil.updatePromotion(cartContext, "", zeroDiscount);
        break;

      case "free":
        setPromoDiscount(zeroDiscount);
        showPromoErrorMessage("This promo code has expired.");
        CartUtil.updatePromotion(cartContext, "", zeroDiscount);
        // console.log("promoDiscount", promoDiscount);
        break;

      case "discount":
        const discount = -49.99;
        hidePromoErrorMessage();
        cartContext.promoDiscount = discount;
        setPromoDiscount(discount);
        CartUtil.updatePromotion(cartContext, promoCode, discount);
        // console.log("promoDiscount", promoDiscount);
        break;

      default:
        setPromoDiscount(zeroDiscount);
        showPromoErrorMessage("Invalid promo code.");
        CartUtil.updatePromotion(cartContext, "", zeroDiscount);
        // console.log("promoDiscount", promoDiscount);
        break;
    }

    // console.log("promoCode", promoCode);
    // console.log("promoDiscount", promoDiscount);
  };

  return (
    <form id="cartPromoCodeForm" className="needs-validation" noValidate>
      <div className="row text-align-center">
        <div className="col form-inline text-right">
          <div className="form-group">
            <label
              htmlFor="cartPromoCode"
              className="col-form-label font-weight-bold mr-2"
            >
              Promotion:
            </label>
            <input
              className="form-control"
              id="cartPromoCode"
              required
              size="15"
              maxLength="15"
              defaultValue={promoCode}
              onBlur={event => {
                setPromoCode(event.target.value);
              }}
            />
            <button
              id="cartPromoCodeApplyBtn"
              className="btn btn-info ml-2"
              type="button"
              onClick={promoClick}
            >
              Apply
            </button>
          </div>
          <span id="discountMessage" className="ml-4">
            Discount applied at checkout ${promoDiscount}
          </span>
        </div>
      </div>
      <div className={`row text-align-center ${errorVisible}`}>
        <div className="col text-center">
          <div id="promoError" className="alert alert-danger" role="alert">
            {promoErrorMessage}
          </div>
        </div>
      </div>
    </form>
  );
}
