import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import compass from "../../images/broken_compass_large.png";

function NotFound() {
  const pageTitle = "404 Error";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });
  return (
    <div id="notFoundPage" className="container">
      <div className="row">
        <div className="col text-center">
          <h1>Oops... you appear to be lost!</h1>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <img
            id="notFoundImg"
            src={compass}
            className="img-fluid rounded"
            alt="not found"
          />
          <p>Have no fear, we will get you back on course.</p>
          <p>
            <Link id="followUsLink" to="/error">
              Follow us!
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
