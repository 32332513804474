import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import PageTitle from "../PageTitle";
import CatalogData from "../../data/CatalogData";
import ProductData from "../../data/ProductData";

// Load Chance
import Chance from "chance";
// Instantiate Chance so it can be used
const chance = new Chance();

const getCategoryName = props => {
  return props.params.name;
};

const getCurrentCategory = desiredCategory => {
  return CatalogData.find(o => o.id === desiredCategory);
};

const getCategoryProducts = name => {
  return ProductData.filter(o => o.categories.includes(name)).sort();
};

/**
 * Calls the /api/v1/products/info/ API and always returns a server 500 error
 */
const callProductInfo = () => {
  // Chance for a 500 API error
  let xhttp = new XMLHttpRequest();
  const data = {
    code: 500,
    delay: chance.integer({ min: 1000, max: 3000 }),
    data: {
      errorCode: 500,
      message: "Server unavailable",
      msgCode: "product_info_error"
    }
  };

  xhttp.open("POST", "https://api.quinnandmurray.com/api/v1/products/info/", true);

  xhttp.onreadystatechange = function() {
    if (this.readyState === 4) {
      var code = this.status;
      var body = JSON.parse(this.responseText);
      console.log(code); // code will match what you set in the post data
      console.log(body); // body will match what you set in the "data" key of post data
    }
  };

  xhttp.setRequestHeader("Content-type", "application/json");

  if (chance.d100() <= 80) {
    xhttp.send(JSON.stringify(data));
  }
};

const ProductList = ({ props, match }) => {
  const [catName] = React.useState(getCategoryName(match));
  const [category] = React.useState(getCurrentCategory(catName));
  const [products] = React.useState(getCategoryProducts(catName));

  const pageTitle = category.title;
  useEffect(() => {
    document.title = `${pageTitle}`;

    if (chance.bool({ likelihood: 40 })) {
      callProductInfo();
    }
  });

  return (
    <div id="productListPage" className="container">
      <PageTitle title={pageTitle} />

      <div className="row align-items-center text-center productList">
        {products.map(item => (
          <div key={item.id} className={`col-sm-6 col-md-4 col-lg-4 product in-stock-${item.inStock}`}>
            <Link id={`${item.id}-link`} className="productCardLink" to={`/product/${item.id}`}>
              <img
                id={`${item.id}-image`}
                src={item.image}
                className="img-thumbnail mx-auto d-block"
                alt={item.title}
              />
              <p id={`${item.id}-title`}>{item.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
