import React, { useEffect } from "react";

import PageTitle from "./PageTitle";
import logo from "../images/logos/quinn-logo-big.png";

const About = () => {
  const pageTitle = "About Q&M";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });
  return (
    <div className="container">
      <PageTitle title={pageTitle} />

      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-4 text-center">
          <img
            id="errorImg"
            src={logo}
            className="img-fluid rounded"
            alt="Internal server error"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-8">
          <p>
            Quinn and Murray has been a purveyor of fine goods since 1983. We
            travel the world to find the highest quality items with distinctive
            style. Our curated products are unique, and when sold out, are no
            longer available. Know that your purchases from Quinn and Murray
            will be limited and make you stand out from the crowd.
          </p>
          <p>
            <small>
              BTW, this is a sample site for software testing and
              demonstrations. Your purchases will never lead to any real
              orders or credit card charges.
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
