import React from "react";

const SocialSharingButtons = () => {
  const handleFacebookClick = () => {
    console.log("Shared to Facebook");
  };
  const handleTwitterClick = () => {
    console.log("Shared to Twitter");
  };
  const handlePinterestClick = () => {
    // console.log("pinterest");
    throw new Error("Pinterest JavaScript error");
  };
  return (
    <div className="row justify-content-center mt-1">
      <div className="col text-center">
        <button
          id="facebookBtn"
          className="btn btn-outline-primary social"
          onClick={handleFacebookClick}
        >
          <i className="fab fa-facebook-square fa-2x" />
          <br />
          Share
        </button>
      </div>
      <div className="col text-center">
        <button
          id="pinterestBtn"
          className="btn btn-outline-danger social"
          onClick={handlePinterestClick}
        >
          <i className="fab fa-pinterest-square fa-2x" />
          <br />
          Pin
        </button>
      </div>
      <div className="col text-center">
        <button
          id="twitterBtn"
          className="btn btn-outline-primary social "
          onClick={handleTwitterClick}
        >
          <i className="fab fa-twitter-square fa-2x" />
          <br />
          Tweet
        </button>
      </div>
    </div>
  );
};

export default SocialSharingButtons;
