import React from "react";

const TechnicalIssuesErrorMessage = props => {
  return (
    <div className="row">
      <div className="col">
        <div
          id="technicalIssuesErrorMsg"
          className={`alert alert-danger ${props.visible}`}
          role="alert"
        >
          Hmm... looks like we're having some issues. Please contact Customer
          Service at <a href="tel:1-800-867-5309">+1 (800) 867-5309</a>.
        </div>
      </div>
    </div>
  );
};

export default TechnicalIssuesErrorMessage;
