import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import ErrorMessage from "../error/ErrorMessage";

import { CheckoutContext } from "../../state/CheckoutContext";
import CheckoutUtil from "../../state/CheckoutUtil";
import UserData from "../../data/UserData";

const LoginForm = ({ history }) => {
  const checkoutContext = useContext(CheckoutContext);

  // Form state
  const [email, setEmail] = useState(checkoutContext.email);
  const [password, setPassword] = useState("");

  // Error message
  const visible = "d-block";
  const hidden = "d-none";
  const [isError, setIsError] = React.useState(hidden);
  const [errorMessage, setErrorMessage] = React.useState("");

  // Handle the submit event
  const handleSubmit = event => {
    if (event) event.preventDefault();

    const form = event.currentTarget;
    if (form && form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    }

    // console.log("email: " + email + ", password: " + password);

    const currentUser = UserData[email];
    // console.log("currentUser", currentUser);

    if (!currentUser) {
      setIsError(visible);
      setErrorMessage("Email not found. Please try again.");

      return;
    }

    if (password !== currentUser.password) {
      setIsError(visible);
      setErrorMessage("Invalid email/password. Please try again.");
    } else {
      setIsError(hidden);
      setErrorMessage("");

      checkoutContext.email = currentUser.email;
      checkoutContext.shippingInfo = currentUser.shippingInfo;
      checkoutContext.billingInfo = currentUser.billingInfo;
      checkoutContext.paymentInfo = currentUser.paymentInfo;

      history.push("/checkout/shipping/");
    }

    CheckoutUtil.update(checkoutContext);
  };

  return (
    <div>
      <ErrorMessage visible={isError} message={errorMessage} />
      <form
        className="form-signin needs-validation"
        noValidate
        onSubmit={handleSubmit}
      >
        <h1 className="h2 mb-3 font-weight-normal text-center">
          Please Sign In
        </h1>
        <label htmlFor="emailLogin" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          id="emailLogin"
          className="form-control"
          placeholder="Email address"
          required
          autoFocus
          defaultValue={email}
          onBlur={event => {
            setEmail(event.target.value);
          }}
        />
        <div className="invalid-feedback">Please enter a valid email.</div>
        <label htmlFor="password" className="sr-only">
          Password
        </label>
        <input
          type="password"
          id="password"
          className="form-control"
          placeholder="Password"
          required
          onBlur={event => {
            setPassword(event.target.value);
          }}
        />
        <div className="invalid-feedback">Please enter a valid password.</div>
        <button id="loginBtn" className="btn btn-lg btn-primary btn-block mt-3" type="submit">
          Sign in
        </button>
      </form>
    </div>
  );
};

export default withRouter(LoginForm);
