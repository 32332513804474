import React, { createContext } from "react";

import CheckoutUtil from "./CheckoutUtil";

const nullState = {
  email: null,
  shippingInfo: {
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: null
  },
  billingInfo: {
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: null
  },
  paymentInfo: {
    name: null,
    number: null,
    expiration: null,
    cvv: null
  }
};

export const CheckoutContext = createContext({ nullState });

export class CheckoutProvider extends React.Component {
  state = CheckoutUtil.fetchDatabase() || nullState;

  render() {
    return (
      <CheckoutContext.Provider value={this.state}>
        {this.props.children}
      </CheckoutContext.Provider>
    );
  }
}

export const CheckoutConsumer = CheckoutContext.Consumer;
