import React from "react";

const ErrorMessage = props => {
  return (
    <div className={`row ${props.visible}`}>
      <div className="col">
        <div id="errorMsg" className={`alert alert-danger`} role="alert">
          {props.message}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
