import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import bike from "../images/antiques-bicycle-bike.jpg";
import suitcase from "../images/adventure-suitcase.jpg";
import suitcaseAndShoes from "../images/suitcase-and-shoes.jpg";

import ProductData from "../data/ProductData";

const HeroImage = props => {
  return (
    <div className="row">
      <div className="col">
        <div id="heroImageContainer">
          <Link to="/catalog">
            <img
              id="heroImg"
              src={props.image}
              className="img-fluid rounded"
              alt="Hero call to action"
            />
          </Link>
          <span className="centered">
            <h1 id="heroTitle">{props.title}</h1>
            <h3 id="heroSubtitle">{props.subtitle}</h3>
            <Link to="/catalog">
              <button id="heroButton" className="btn btn-info">
                Shop Now
              </button>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

const FeaturedProducts = () => {
  const getProduct = desiredId => {
    return ProductData.find(product => product.id === desiredId);
  };

  const [hat] = React.useState(getProduct("panama-safari-hat"));
  const [watch] = React.useState(
    getProduct("hamilton-spirit-of-liberty-classic-watch")
  );
  const [bag] = React.useState(getProduct("leather-bike-messenger-bag"));

  return (
    <div className="card-deck align-items-end">
      <FeaturedProductCard product={hat} />
      <FeaturedProductCard product={watch} />
      <FeaturedProductCard product={bag} />
    </div>
  );
};

const FeaturedProductCard = props => {
  return (
    <div
      id={`${props.product.id}-featuredcontainer`}
      className="card text-center border-0 mb-4 featuredProduct"
    >
      <Link to={`/product/${props.product.id}`}>
        <img
          id={`${props.product.id}-featuredimage`}
          src={props.product.image}
          className="card-img-top"
          alt={props.product.title}
        />
      </Link>
      <div className="card-body">
        <Link
          id={`${props.product.id}-featuredlink`}
          to={`/product/${props.product.id}`}
        >
          <div id={`${props.product.id}-featuredtitle`} className="card-title">
            {props.product.title}
          </div>
        </Link>
      </div>
    </div>
  );
};

const HomePage = ({ props, location }) => {
  const campaignB = "HeroB";

  // const utm_source = "Google";
  const utm_campaign =
    (!!window.location.search.split("utm_campaign=")[1] &&
      window.location.search.split("utm_campaign=")[1].split("&")[0]) ||
    "HeroA";
  // console.log("utm_source: ", utm_source);
  // console.log("utm_campaign: ", utm_campaign);

  const pageTitle = "Quinn & Murray";
  useEffect(() => {
    document.title = `${pageTitle}`;

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    if (utm_campaign === campaignB) {
      window.dataLayer.push({
        campaign: "Homepage Conversion",
        experiment: "Homepage CTA",
        variation: "Variation #1"
      });
    } else {
      window.dataLayer.push({
        campaign: "Homepage Conversion",
        experiment: "Homepage CTA",
        variation: "Original"
      });
    }
  });

  return (
    <div id="homePage" className="container">
      {utm_campaign === campaignB ? (
        <span>
          <FeaturedProducts />

          <HeroImage
            image={suitcaseAndShoes}
            title="Stand out from the crowd"
            subtitle="Eclectic items for sophisticated tastes"
          />
        </span>
      ) : (
        <span>
          <HeroImage
            image={bike}
            title="Explore the possibilities"
            subtitle="Eclectic items for sophisticated tastes"
          />

          <FeaturedProducts />
        </span>
      )}

      <div className="row align-items-center">
        <div className="col-sm-6">
          <img
            id="suitcaseImg"
            src={suitcase}
            className="img-thumbnail"
            alt="Learn more"
          />
        </div>
        <div className="col-sm-6">
          <strong>Unique products from unique places</strong>
          <p>
            We travel the world looking for the highest quality items that
            represent the diversity of cultures and styles from around the
            globe... so that you don't have to.
          </p>
          <Link to="/about/">Learn more about us</Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
