import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import { CheckoutContext } from "../../state/CheckoutContext";
import CheckoutUtil from "../../state/CheckoutUtil";
import ErrorMessage from "../error/ErrorMessage";

const ContinueAsGuestForm = ({ history }) => {
  const checkoutContext = useContext(CheckoutContext);

  // Form state
  const [email, setEmail] = useState(checkoutContext.email);

  // Error message
  const visible = "d-block";
  const hidden = "d-none";
  const [isError, setIsError] = React.useState(hidden);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = event => {
    if (event) event.preventDefault();

    const form = event.currentTarget;
    if (form && form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    }

    if (!email || email === undefined || email.length === 0) {
      setIsError(visible);
      setErrorMessage("Invalid email. Please try again.");

      return;
    } else {
      // CheckoutUtil.empty(checkoutContext);
      setIsError(hidden);
      setErrorMessage("");

      checkoutContext.email = email;
      checkoutContext.shippingInfo = {};
      checkoutContext.billingInfo = {};
      checkoutContext.paymentInfo = {};
      CheckoutUtil.empty(checkoutContext);

      CheckoutUtil.update(checkoutContext);

      history.push("/checkout/shipping");
    }
  };

  return (
    <div>
      <h1 className="h2 mb-3 font-weight-normal text-center">
        Continue as Guest
      </h1>
      <ErrorMessage visible={isError} message={errorMessage} />
      <form className="needs-validation" noValidate onSubmit={handleSubmit}>
        <label htmlFor="emailGuest" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          id="emailGuest"
          className="form-control"
          placeholder="Guest email address"
          required
          defaultValue={email}
          onBlur={event => {
            setEmail(event.target.value);
          }}
        />
        <div className="invalid-feedback">Please enter a valid email.</div>
        <button
          id="continueAsGuestBtn"
          className="btn btn-lg btn-outline-primary btn-block mt-5"
          type="submit"
        >
          Continue
        </button>
      </form>
    </div>
  );
};

export default withRouter(ContinueAsGuestForm);
