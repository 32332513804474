const UserData = {
  "alex@example.com": {
    email: "alex@example.com",
    password: "password123",
    shippingInfo: {
      firstName: "Alex",
      lastName: "Anderson",
      address1: "2706 Sycamore St",
      address2: "",
      city: "Alexandria",
      state: "VA",
      zip: "22305",
      country: "US"
    },
    billingInfo: {
      firstName: "Alex",
      lastName: "Anderson",
      address1: "2706 Sycamore St",
      address2: "",
      city: "Alexandria",
      state: "VA",
      zip: "22305",
      country: "US"
    },
    paymentInfo: {
      name: "Alex Anderson",
      number: "4242 4242 4242 4242",
      expiration: "01/23",
      cvv: "487"
    }
  },
  "bao@example.com": {
    email: "bao@example.com",
    password: "password123",
    shippingInfo: {
      firstName: "Bao",
      lastName: "Chen",
      address1: "1943 Mandeville Canyon Rd",
      address2: "",
      city: "Los Angeles",
      state: "CA",
      zip: "90049",
      country: "US"
    },
    billingInfo: {
      firstName: "Bao",
      lastName: "Chen",
      address1: "1943 Mandeville Canyon Rd",
      address2: "",
      city: "Los Angeles",
      state: "CA",
      zip: "90049",
      country: "US"
    },
    paymentInfo: {
      name: "Bao Chen",
      number: "4111 1111 1111 1111",
      expiration: "09/24",
      cvv: "958"
    }
  },
  "maria@example.com": {
    email: "maria@example.com",
    password: "password123",
    shippingInfo: {
      firstName: "Maria",
      lastName: "Gonzalez",
      address1: "170 Amory St",
      address2: "",
      city: "Boston",
      state: "MA",
      zip: "02130",
      country: "US"
    },
    billingInfo: {
      firstName: "Maria",
      lastName: "Gonzalez",
      address1: "170 Amory St",
      address2: "",
      city: "Boston",
      state: "MA",
      zip: "02130",
      country: "US"
    },
    paymentInfo: {
      name: "Maria Gonzalez",
      number: "1234 1234 1234 1234",
      expiration: "11/23",
      cvv: "112"
    }
  },
  "sarah@example.com": {
    email: "sarah@example.com",
    password: "password123",
    shippingInfo: {
      firstName: "Sarah",
      lastName: "Schmidt",
      address1: "3204 Windy Ridge Pkwy SE",
      address2: "Ste 504",
      city: "Atlanta",
      state: "GA",
      zip: "30339",
      country: "US"
    },
    billingInfo: {
      firstName: "Sarah",
      lastName: "Schmidt",
      address1: "3204 Windy Ridge Pkwy SE",
      address2: "Ste 504",
      city: "Atlanta",
      state: "GA",
      zip: "30339",
      country: "US"
    },
    paymentInfo: {
      name: "Sarah Schmidt",
      number: "2323 2323 2323 2323",
      expiration: "02/23",
      cvv: "642"
    }
  }
};

export default UserData;
