import React from "react";
import { Link, withRouter } from "react-router-dom";
import ProductData from "../../data/ProductData";

const RelatedProductCard = ({ product, setProductName, setProduct }) => {
  const filterData = id => {
    return ProductData.filter(product => product.id === id);
  };
  return (
    <div
      key={product.id}
      className="col-sm-3 col-md-3 col-lg-3 text-center  product"
      onClick={() => {
        setProductName(product.id);
        setProduct(filterData(product.id)[0]);
      }}
    >
      <Link
        id={`${product.id}-link`}
        className="productCardLink"
        to={`/product/${product.id}`}
      >
        <img
          id={`${product.id}-image`}
          src={product.image}
          className="img-thumbnail d-block relatedProduct-image"
          alt={product.title}
        />
        <p
          id={`${product.id}-title`}
          className="text-muted relatedProduct-title"
        >
          {product.title}
        </p>
      </Link>
    </div>
  );
};

export default withRouter(RelatedProductCard);
