import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PageTitle from "./PageTitle";
import ProductData from "../data/ProductData";

const SearchError = () => {
  return (
    <tr>
      <td>&nbsp;</td>
      <td>
        <div id="searchError" className="alert alert-danger text-center">
          Sorry, we encountered an error with your search.
        </div>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
};

const EmptySearchError = searchTerm => {
  return (
    <tr>
      <td>&nbsp;</td>
      <td>
        <div id="emptySearchError" className="alert alert-danger text-center">
          Sorry, we couldn't find any results that match your search.
        </div>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
};

const ProductSearchResults = ({ search }) => {
  const [results] = useState(ProductData);

  if (search === "mens") {
    return <SearchError />;
  }

  let tmp = results.filter(
    product =>
      product.title.toLowerCase().includes(search.toLowerCase()) ||
      product.description.toLowerCase().includes(search.toLowerCase())
  );

  if (tmp.length === 0) {
    return <EmptySearchError />;
  }

  return tmp.map(item => (
    <tr key={item.id}>
      <td>
        <Link
          to={`/product/${item.id}`}
          className="searchLink text-decoration-none"
        >
          <img
            src={item.image}
            className="searchImg img-rounded"
            alt={item.title}
          />
        </Link>
      </td>
      <td>
        <Link
          to={`/product/${item.id}`}
          className="searchLink text-decoration-none"
        >
          <h5>{item.title}</h5>
          <small className="text-muted">{item.description}</small>
        </Link>
      </td>
      <td>
        <Link
          to={`/product/${item.id}`}
          className="searchLink text-decoration-none"
        >
          ${item.price}
        </Link>
      </td>
    </tr>
  ));
};

function SearchPage() {
  const [searchField, setSearchField] = useState("");

  const pageTitle = "Search";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });

  return (
    <div id="searchPage" className="container">
      <PageTitle title={pageTitle} />
      <div className="row">
        <div className="col">
          <div className="input-group mt-4 mb-2 mr-sm-2">
            <input
              type="input"
              className="form-control form-control-lg text-center"
              id="search"
              aria-describedby="Search"
              placeholder="Your adventure awaits"
              autoFocus
              autoComplete="off"
              defaultValue={searchField}
              onBlur={event => {
                setSearchField(event.target.value);
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <i id="searchIcon" className="fas fa-search" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col">
          <table className="table">
            <tbody>
              <ProductSearchResults search={searchField} />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
