import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import PageTitle from "../PageTitle";
import CatalogData from "../../data/CatalogData";

const CatalogHome = () => {
  const [categories] = React.useState(CatalogData.sort());

  const pageTitle = "Catalog";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });

  return (
    <div id="catalogPage" className="container">
      <PageTitle title={pageTitle} />

      <div className="row align-items-center text-center categoryList">
        {categories.map(item => (
          <div key={item.id} className="col-sm-6 col-md-4 col-lg-4 category">
            <Link
              id={`${item.id}-link`}
              className="categoryCardLink"
              to={`/catalog/${item.id}`}
            >
              <img
                id={`${item.id}-image`}
                src={item.image}
                className="img-thumbnail"
                alt={item.title}
              />
              <p id={`${item.id}-title`}>{item.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatalogHome;
