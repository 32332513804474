import React, { useState, useEffect } from "react";
import Spinner from "react-svg-spinner";

import ProductData from "../../data/ProductData";
import RelatedProductCard from "./RelatedProductCard";
import { withRouter } from "react-router-dom";

// Load Chance
import Chance from "chance";
// Instantiate Chance so it can be used
const chance = new Chance();

const RelatedProductsList = props => {
  const [products, setProducts] = useState([]);
  const hidden = "d-none";

  useEffect(() => {
    const numberOfProducts = 3;
    const delay = chance.integer({ min: 2000, max: 3500 });

    // Chance for a 404 API error
    let xhttp = new XMLHttpRequest();
    const data = {
      code: 404,
      delay: chance.integer({ min: 1500, max: 4000 }),
      data: {
        errorCode: 404,
        message: "Server unavailable",
        msgCode: "product_info_error"
      }
    };

    xhttp.open("POST", "https://api.quinnandmurray.com/api/v1/products/related/details/", true);

    xhttp.onreadystatechange = function() {
      if (this.readyState === 4) {
        var code = this.status;
        var body = JSON.parse(this.responseText);
        console.log(code); // code will match what you set in the post data
        console.error(body); // body will match what you set in the "data" key of post data
      }
    };

    xhttp.setRequestHeader("Content-type", "application/json");

    setTimeout(() => {
      if (chance.bool()) {
        xhttp.send(JSON.stringify(data));
      }

      props.setSpinnerVisible(hidden);
      setProducts(chance.pickset(ProductData, numberOfProducts));
      // console.log(products);
    }, delay);
  }, [props]);

  return products.map(product => {
    return (
      <RelatedProductCard
        key={product.id}
        product={product}
        setProductName={props.setProductName}
        setProduct={props.setProduct}
      />
    );
  });
};

const RelatedProducts = props => {
  const visible = "d-block";
  const [spinnerVisible, setSpinnerVisible] = useState(visible);
  let delay = 0;

  if (!!props.productName && props.productName.includes("shirt")) {
    delay = chance.integer({ min: 2000, max: 4000 });
  } else {
    delay = chance.integer({ min: 50, max: 300 });
  }

  let xhttp = new XMLHttpRequest();
  const data = {
    code: 200,
    delay: delay,
    data: {
      errorCode: 200,
      message: "Server unavailable",
      msgCode: "product_info_error"
    }
  };

  xhttp.open("POST", "https://api.quinnandmurray.com/api/v1/products/related/list/", true);

  xhttp.onreadystatechange = function() {
    if (this.readyState === 4) {
      var code = this.status;
      var body = JSON.parse(this.responseText);
      console.log(code); // code will match what you set in the post data
      console.error(body); // body will match what you set in the "data" key of post data
    }
  };

  xhttp.setRequestHeader("Content-type", "application/json");

  xhttp.send(JSON.stringify(data));

  return (
    <div id="relatedProducts">
      <div className="row">
        <div className="col">
          <h5 className="text-muted">Related Items</h5>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-1">&nbsp;</div>
        <span id="spinner" className={spinnerVisible}>
          <Spinner size="64px" color="grey" />
        </span>

        <RelatedProductsList
          setProductName={props.setProductName}
          setProduct={props.setProduct}
          setSpinnerVisible={setSpinnerVisible}
        />
        <div className="col-lg-1">&nbsp;</div>
      </div>
    </div>
  );
};

export default withRouter(RelatedProducts);
