import React from "react";

const SizeGuideText = () => {
  return (
    <div className="row">
      <div className="col">
        <a id="truefitText" href="#/sizeguide" className="sizeFinder">
          Find Your True Fit
        </a>
      </div>
    </div>
  );
};

export default SizeGuideText;
