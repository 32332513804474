import React, { useEffect } from "react";

import PageTitle from "./PageTitle";

const getCustomerServiceCode = () => {
  let code = sessionStorage.getItem("QMCustomerServiceCode");
  // console.log("code:", code);

  if (!code) {
    // Function from https://stackoverflow.com/a/8084248
    code = Math.random()
      .toString(36)
      .substring(7)
      .toUpperCase();
    sessionStorage.setItem("QMCustomerServiceCode", code);
    // console.log("code:", code);
  }

  return code;
};

const ContactPage = ({ history }) => {
  const customerServiceCode = getCustomerServiceCode();
  const emojiStyle = {
    fontSize: "20px"
  };

  const pageTitle = "Contact Q&M";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });

  const handleSubmit = event => {
    if (event) event.preventDefault();

    const form = event.currentTarget;
    if (form && form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    }

    history.push("/thankyou");
  };

  return (
    <div className="container">
      <PageTitle title={pageTitle} />

      <div className="row mb-md-3">
        <div className="col">
          <p>
            We hope that you've enjoyed the adventure we've provided. Please let
            us know how we're doing.{" "}
            <span
              id="happyEmoji"
              style={emojiStyle}
              role="img"
              aria-label="Happy face"
            >
              &#128512;
            </span>
            <span
              id="sadEmoji"
              style={emojiStyle}
              role="img"
              aria-label="Sad face"
            >
              &#128577;
            </span>
          </p>
          <p>
            Call us at <a href="tel:1-800-867-5309">1-800-867-5309</a> &nbsp;
            M-F 8am-6pm PT
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <p className="mr-1">
              Customer Service ID:{" "}
              <span
                id="customerServiceId"
                className="font-weight-bold qm-servicecode"
              >
                {/* Stored in sessionStorage */}
                {customerServiceCode}
              </span>
              <br />
              <small className="text-muted">
                Your unique customer service ID to provide you excellent
                service.
              </small>
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-md-right">
        <div className="col col-lg-8">
          <p className="h5">Or please send us a message:</p>
          <form className="needs-validation" noValidate onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                maxLength="20"
                required
                placeholder="name@example.com"
              />
              <div className="invalid-feedback">
                Email is required so that we can contact you later.
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                className="form-control"
                id="phone"
                maxLength="15"
                required
                placeholder="202-555-3322"
              />
              <div className="invalid-feedback">
                Phone is required in case the email doesn't work.
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                required
                rows="3"
              />
              <div className="invalid-feedback">
                Message is required so that we can understand your request.
              </div>
            </div>

            <button
              id="contactSubmitBtn"
              type="submit"
              className="btn btn-primary"
            >
              Contact Us
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
