import React from "react";

import Image from "react-bootstrap/Image";
import truefitButtonImg from "../../images/truefit-button.png";

const SizeGuideImage = () => {
  return (
    <div className="row">
      <div className="col">
        <p>
          <a href="#/sizeguide">
            <Image
              className="sizeFinder"
              id="truefitBtn"
              src={truefitButtonImg}
            />
          </a>
        </p>
      </div>
    </div>
  );
};

export default SizeGuideImage;
