import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import PageTitle from "./PageTitle";
import logo from "../images/logos/quinn-logo-big.png";

const ThankYouPage = () => {
  const pageTitle = "Thank You";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });
  return (
    <div className="container">
      <PageTitle title={pageTitle} />

      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-4 text-center">
          <img
            id="bigLogo"
            src={logo}
            className="img-fluid rounded"
            alt="Big logo"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-8">
          <p className="text-center">
            Thank you for contacting Quinn &amp; Murray. We will be in touch
            shortly.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col text-center mt-5">
          <p className="h5">
            <Link to="/catalog">
              <button type="button" className="btn btn-success">
                Continue Shopping
              </button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
