import React from "react";
import { Link, NavLink } from "react-router-dom";

import Image from "react-bootstrap/Image";

import logo from "../images/logos/quinn-logo.png";

const NavigationLink = props => {
  return (
    <li className="nav-item">
      <NavLink id={props.id} to={props.path} className="nav-link">
        {props.text}
      </NavLink>
    </li>
  );
};

const Navigation = () => {
  return (
    <header>
      <nav
        className="navbar navbar-expand-md navbar-light bg-white align-items-center"
        role="navigation"
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <Image id="navLogo" src={logo} fluid />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse text-center"
            id="navbarCollapse"
          >
            <ul className="navbar-nav mr-auto">
              <NavigationLink id="navCatalog" path="/catalog" text="Catalog" />
              <NavigationLink
                id="navSaleItems"
                path="/sale-items"
                text="Sale Items"
              />
              <NavigationLink id="navRewards" path="/rewards" text="Rewards" />
              <NavigationLink id="navAbout" path="/about" text="About Us" />
              <NavigationLink
                id="navContact"
                path="/contact"
                text="Contact Us"
              />
            </ul>

            <form className="form-inline mt-2 mt-md-0">
              <Link
                id="navSearch"
                to="/search"
                className="nav-link btn btn-outline my-2 my-sm-0"
              >
                <i className="fas fa-search" />
              </Link>
              <Link
                id="navCart"
                to="/checkout/cart"
                className="nav-link btn btn-success my-2 my-sm-0"
              >
                <i className="fas fa-shopping-cart" />
              </Link>
            </form>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
