import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";

import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./components/HomePage";
import RewardsPage from "./components/RewardsPage";
import About from "./components/AboutPage";
import Contact from "./components/ContactPage";
import ThankYouPage from "./components/ThankYouPage";
import Search from "./components/SearchPage";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import NotFound from "./components/error/NotFoundPage";
import InternalError from "./components/error/InternalErrorPage";

import LoginPage from "./components/login/LoginPage";

// Products
import ProductDetailsPage from "./components/product/ProductDetailsPage";
import ProductList from "./components/product/ProductListPage";
import CatalogHome from "./components/product/CatalogHomePage";

// Checkout
import Cart from "./components/checkout/CartPage";
import Confirmation from "./components/checkout/ConfirmationPage";
import Payment from "./components/checkout/PaymentPage";
import Review from "./components/checkout/ReviewPage";
import Shipping from "./components/checkout/ShippingPage";

import { CartProvider } from "./state/CartContext";
import { CheckoutProvider } from "./state/CheckoutContext";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <CartProvider>
          <CheckoutProvider>
            <div>
              <Navigation />

              <main role="main">
                <div className="container">
                  <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/rewards" component={RewardsPage} />
                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/thankyou" component={ThankYouPage} />
                    <Route path="/search" component={Search} />
                    <Route path="/error" component={InternalError} />

                    <Route path="/catalog" exact component={CatalogHome} />
                    <Route path="/catalog/:name" component={ProductList} />
                    <Route
                      path="/product/:name"
                      component={ProductDetailsPage}
                    />

                    <Route path="/login/" exact component={LoginPage} />

                    <Route path="/checkout/" exact component={Cart} />
                    <Route path="/checkout/cart" component={Cart} />
                    <Route path="/checkout/shipping" component={Shipping} />
                    <Route path="/checkout/payment" component={Payment} />
                    <Route path="/checkout/review" component={Review} />
                    <Route
                      path="/checkout/confirmation"
                      component={Confirmation}
                    />

                    {/* Must be the last route */}
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </main>

              <Footer />
            </div>
          </CheckoutProvider>
        </CartProvider>
      </ScrollToTop>
    </Router>
  );
};

export default App;
