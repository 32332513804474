import React from "react";
import { Link } from "react-router-dom";

const RewardsBanner = () => {
  let bannerStyle = {
    marginTop: "5px",
    marginBottom: "25px",
    padding: "15px",
    backgroundColor: "#6A8896",
    color: "white"
  };

  let linkStyle = {
    color: "white",
    textDecoration: "underline",
    fontWeight: "bold"
  };

  return (
    <div id="rewardsBanner" className="row">
      <div className="col-lg-1">&nbsp;</div>
      <div className="col text-center rounded" style={bannerStyle}>
        <Link id="rewardsBannerLink" to="/rewards" style={linkStyle}>
          Join Q&amp;M Rewards Now!
        </Link>
        &nbsp;&nbsp;Save $50 on your next purchase.
      </div>
      <div className="col-lg-1">&nbsp;</div>
    </div>
  );
};

export default RewardsBanner;
