import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { CheckoutContext } from "../../state/CheckoutContext";
import CheckoutUtil from "../../state/CheckoutUtil";
import { CartContext, CartConsumer } from "../../state/CartContext";

import ErrorMessage from "../error/ErrorMessage";
import CartItemList from "./CartItemList";

import RewardsBanner from "../RewardsBanner";

const ReviewDetailsCard = props => {
  return (
    <div id={props.id} className="card">
      <div className="card-header card-title text-right">
        <Link id={`${props.id}-edit-link`} to={props.editLink}>
          Edit
        </Link>
      </div>
      <div className="card-body">
        <h5 className="card-title">{props.title}</h5>
        <p className="card-text">
          <span id="line1">{props.line1}</span>
          <br />
          <span id="line2">{props.line2}</span>
          <br />
          <span id="line3">{props.line3}</span>
          <br />
          <span id="line4">{props.line4}</span>
        </p>
      </div>
    </div>
  );
};

const SubtotalText = props => {
  return (
    <div className="row align-items-center">
      <div className="col-8">
        <div className="form-group row align-items-center">&nbsp;</div>
      </div>
      <div className="col-sm-2 text-right h6">Subtotal:</div>
      <div className="col-sm-2">
        <span id="subTotal" className="h6">
          <CartConsumer>
            {({ subtotal }) => <span>${props.amount}</span>}
          </CartConsumer>
        </span>
      </div>
    </div>
  );
};

const PromoDiscountText = props => {
  return (
    <div className="row align-items-center">
      <div className="col-6">
        <div className="form-group row align-items-center">&nbsp;</div>
      </div>
      <div className="col-sm-4 text-right">Discount:</div>
      <div id="subTotal" className="col-sm-2">
        ${props.amount}
      </div>
    </div>
  );
};

const ShippingText = props => {
  return (
    <div className="row align-items-center">
      <div className="col-6">
        <div className="form-group row align-items-center">&nbsp;</div>
      </div>
      <div className="col-sm-4 text-right">Shipping &amp; Taxes:</div>
      <div id="subTotal" className="col-sm-2">
        ${props.amount}
      </div>
    </div>
  );
};

const GrandTotalText = props => {
  return (
    <div className="row align-items-center">
      <div className="col-6">
        <div className="form-group row align-items-center">&nbsp;</div>
      </div>
      <div className="col-sm-4 text-right h4">Grand Total:</div>
      <div id="grandTotal" className="col-sm-2 h4">
        ${props.amount}
      </div>
    </div>
  );
};

const PurchaseButton = () => {
  return (
    <div className="row justify-content-end">
      <div className="col col-lg-10 text-right">&nbsp;</div>
      <div className="col">
        <button
          id="checkoutBtn"
          type="submit"
          className="btn btn-primary btn-lg"
        >
          Purchase
        </button>
      </div>
    </div>
  );
};

const Review = ({ history }) => {
  const cartContext = useContext(CartContext);
  // console.log("cartContext:", cartContext);
  const checkoutContext = useContext(CheckoutContext);
  // console.log("checkoutContext", checkoutContext);

  const pageTitle = "Review Your Order";
  useEffect(() => {
    document.title = `${pageTitle}`;

    // Forward to the Cart page is the cart is empty
    if (!cartContext || !cartContext.items || cartContext.items.length === 0) {
      history.push("/checkout/cart");
    }

    CheckoutUtil.update(checkoutContext);
  });

  const [isError, setIsError] = React.useState("d-none");
  const [errorMessage, setErrorMessage] = React.useState("");
  const ccDeclinedErrorMessage =
    "Card declined. Please try another form of payment.";
  const badOrderErrorMessage =
    "Sorry, we had a problem processing your order. Please try again.";

  const handleSubmit = event => {
    if (event) event.preventDefault();

    const ccNumber = checkoutContext.paymentInfo.number;
    if (!ccNumber || ccNumber.startsWith("1")) {
      setIsError("d-block");
      setErrorMessage(ccDeclinedErrorMessage);
    } else if (ccNumber.startsWith("2")) {
      setIsError("d-block");
      setErrorMessage(badOrderErrorMessage);
    } else {
      setIsError("d-none");
      history.push("/checkout/confirmation");
    }
  };

  return (
    <div id="reviewPage" className="container">
      <RewardsBanner />

      <div className="row">
        <div className="col">
          <h1>{pageTitle}</h1>
        </div>
      </div>

      <hr className="mb-4" />
      <ErrorMessage visible={isError} message={errorMessage} />
      <div className="card-deck">
        <ReviewDetailsCard
          id="shippingDetails"
          title="Shipping"
          line1={`${checkoutContext.shippingInfo.firstName} ${checkoutContext.shippingInfo.lastName}`}
          line2={`${checkoutContext.shippingInfo.address1}`}
          line3={`${checkoutContext.shippingInfo.city} ${checkoutContext.shippingInfo.state}, ${checkoutContext.shippingInfo.zip}`}
          line4={checkoutContext.shippingInfo.country}
          editLink="/checkout/shipping"
        />

        <ReviewDetailsCard
          id="paymentDetails"
          title="Payment"
          line1={checkoutContext.paymentInfo.name}
          line2={checkoutContext.paymentInfo.number}
          line3={checkoutContext.paymentInfo.expiration}
          editLink="/checkout/payment"
        />
      </div>

      <div className="row">
        <div className="col">
          <CartItemList />

          <hr className="mb-4" />

          <SubtotalText amount={cartContext.subtotal} />
          <PromoDiscountText amount={cartContext.promoDiscount} />
          <ShippingText amount={cartContext.shippingFee} />
          <GrandTotalText amount={cartContext.grandTotal} />

          <hr className="mb-4" />
          <form onSubmit={handleSubmit}>
            <PurchaseButton />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Review;
