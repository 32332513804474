import React, { useContext, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import ReactImageMagnify from "react-image-magnify";
import StarRatings from "react-star-ratings";

import { CartContext } from "../../state/CartContext";
import ProductReviews from "./ProductReviews";
import SocialSharingButtons from "./SocialSharingButtons";
import ProductData from "../../data/ProductData";
import CartUtil from "../../state/CartUtil";

import RelatedProducts from "./RelatedProducts";
import SizeGuideImage from "./SizeGuideImage";
import SizeGuideText from "./SizeGuideText";

const PdpError = ({ message, display }) => {
  return (
    <div className={`row justify-content-center mt-5 ${display}`}>
      <p className="mt-5">&nbsp;</p>
      <div className="col text-center mt-5">
        <div id="productError" className="alert alert-danger" role="alert">
          {message}
        </div>
      </div>
    </div>
  );
};

const AddToCartButton = ({ product, click }) => {
  return (
    <button id="addToCartBtn" className="btn btn-block btn-success" onClick={click}>
      Add to Cart
    </button>
  );
};

const OutOfStockError = () => {
  return (
    <div className="text-center justify-content-center block">
      <div id="outOfStockError" className="alert alert-danger" role="alert">
        Out of Stock
      </div>
    </div>
  );
};

const getProductName = props => {
  return props.params.name;
};

const getProduct = desired => {
  return ProductData.find(o => o.id === desired);
};

const ProductDetails = ({ match, history }) => {
  const cartContext = useContext(CartContext);
  const [quantity, setQuantity] = React.useState(0);
  const [productName, setProductName] = React.useState(getProductName(match));
  const [product, setProduct] = React.useState(getProduct(productName));
  const [fulfillmentType, setFulfillmentType] = React.useState("delivery");

  const [rating, setRating] = React.useState(product.rating);

  const visible = "d-block";
  const hidden = "d-none";
  const [pdpErrorIsVisible, setPdpErrorState] = React.useState(hidden);
  const [pdpErrorMessage, setPdpErrorMessage] = React.useState("");

  const httpClient = axios.create();
  httpClient.defaults.timeout = 500;

  useEffect(() => {
    document.title = `${product.title}`;

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer = window.dataLayer.filter(function(el) {
      return !el.pdp;
    });

    window.dataLayer.push({
      pdp: {
        name: `${product.title}`,
        price: `${product.price}`,
        rating: `${product.rating}`
      }
    });
  });

  const updateFufillment = event => {
    setFulfillmentType(event.target.value);
  };

  const changeRating = newRating => {
    setRating(newRating);
  };

  const handleChangeQuantity = () => {
    httpClient
      .get("https://api.dynamicyeild.com/personalize")
      .then(function(response) {
        // It will never succeed
      })
      .catch(function(error) {
        // It will always error
        console.log(error);
      });
  };

  const handleAddToCart = () => {
    if (quantity > 0) {
      setPdpErrorState(hidden);
      setPdpErrorMessage("");
    } else {
      setPdpErrorState(visible);
      setPdpErrorMessage("Quantity must be greater than 0.");

      return;
    }

    // if (document.URL.includes("leather-bike-messenger-bag")) {
    //   console.error("Leather Bike Messenger Bag not available");
    //   return;
    // }

    CartUtil.addItem(cartContext, product, quantity);

    history.push("/checkout/cart");
  };

  const handleSizeGuide = () => {
    if (productName.includes("italian-motorcycle-jacket")) {
      return <SizeGuideImage />;
    } else if (productName.includes("mens-civil-war-coat")) {
      return <SizeGuideText />;
    }
  };

  return (
    <div id="productDetailsPage" className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-4">
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: product.title,
                isFluidWidth: true,
                src: product.image
              },
              largeImage: {
                src: product.image,
                width: 800,
                height: 800
              },
              enlargedImagePosition: "over",
              enlargedImageContainerDimensions: {
                width: "150%",
                height: "150%"
              },
              isHintEnabled: true
            }}
          />
        </div>
        <div className="col-lg-6">
          <h1 id="productName">{product.title}</h1>
          <h5 id="productPrice" className="text-muted">
            ${product.price}
          </h5>
          <div className="align-items-center">
            <StarRatings
              rating={rating}
              starRatedColor="blue"
              changeRating={changeRating}
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starSpacing="2px"
            />
            <small id="leaveReview" className="text-muted ml-2">
              Leave a Review
            </small>
          </div>
          <p id="productDescription" className="font-weight-light">
            {product.description}
          </p>

          {handleSizeGuide()}
          <div className="row align-items-center">
            {/* Create OnValueChange Function */}
            <div onChange={updateFufillment} className="form-check-inline ml-3">
              <input
                type="radio"
                id="delivery"
                name="fulfillment"
                value="delivery"
                className="form-check-input p-1"
                checked={fulfillmentType === "delivery" ? true : false}
              />
              <label htmlFor="delivery" className="form-check-label p-1 mr-2">
                Delivery (2-5 Days)
              </label>
              <br />
              <input type="radio" id="pickup" name="fulfillment" value="pickup" className="form-check-input p-1" />
              <label htmlFor="pickup" className="form-check-label p-1">
                Pickup in Store
              </label>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-2 col-md-2 col-lg-2 form-group">
              <label htmlFor="quantity" className="font-weight-bold">
                Quantity
              </label>
              <input
                id="quantity"
                className="form-control"
                defaultValue={quantity}
                maxLength="2"
                onBlur={e => setQuantity(e.target.value)}
                onChange={e => handleChangeQuantity()}
              />
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10">
              {(fulfillmentType === "delivery" && product.inStock) ||
              (fulfillmentType === "pickup" && product.inStorePickup) ? (
                <AddToCartButton product={product} click={handleAddToCart} />
              ) : (
                <OutOfStockError />
              )}
            </div>
          </div>

          <SocialSharingButtons />

          <PdpError display={pdpErrorIsVisible} message={pdpErrorMessage} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <RelatedProducts
            key={productName}
            productName={productName}
            setProductName={setProductName}
            setProduct={setProduct}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ProductReviews reviews={product.reviews} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProductDetails);
