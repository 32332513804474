import React from "react";

const zero = (0.0).toFixed(2);
const cartNameLocalStorage = "cart";

const updateItemTotal = (price, quantity) => {
  return (quantity * price).toFixed(2);
};

const CartUtil = {
  update(cartContext) {
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    this.updateEachItemTotal(cartContext);
    this.updateCartSubtotal(cartContext);
    this.updateGrandTotal(cartContext);
    this.updateDatabase(cartContext);
  },
  updateDatabase: function(cartContext) {
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    localStorage.setItem(cartNameLocalStorage, JSON.stringify(cartContext));
  },
  fetchDatabase: function() {
    const cart = localStorage.getItem(cartNameLocalStorage);
    return JSON.parse(cart);
  },
  addItem: function(cartContext, product, quantity) {
    // console.log("cartContext", cartContext);
    // TODO: Check if product exists, if so, update quantity
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    let newItem = {
      quantity: quantity,
      itemTotal: updateItemTotal(product.price, quantity),
      product: product
    };

    // console.log("add item");
    cartContext.items.push(newItem);

    this.update(cartContext);
  },
  removeItem: function(cartContext, index) {
    // console.log("cartContext", cartContext);
    // console.log("Removing item");
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    cartContext.items.splice(index, 1);

    this.update(cartContext);
  },
  updateItem: function(cartContext, item, quantity) {
    item.quantity = quantity;
    this.update(cartContext);
  },
  getItems: function(cartContext) {
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    return cartContext.items;
  },
  updatePromotion(cartContext, promoCode, promoDiscount) {
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    cartContext.promoCode = promoCode;
    cartContext.promoDiscount = promoDiscount;
    this.update(cartContext);
    // console.log(cartContext);
  },
  updateEachItemTotal: function(cartContext) {
    // console.log("updateSubtotal");

    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    let item = null;
    for (var i = 0; i < cartContext.items.length; i++) {
      item = cartContext.items[i];
      item.itemTotal = updateItemTotal(item.product.price, item.quantity);
    }
  },
  updateCartSubtotal: function(cartContext) {
    // console.log("updateSubtotal");
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    let tmp = 0;
    let sum = 0;
    for (var i = 0; i < cartContext.items.length; i++) {
      tmp = cartContext.items[i].itemTotal;
      tmp = parseFloat(tmp);
      sum += tmp;
    }
    cartContext.subtotal = sum.toFixed(2);
    cartContext.subtotal =
      parseFloat(cartContext.subtotal) + parseFloat(cartContext.promoDiscount);
    cartContext.subtotal = cartContext.subtotal.toFixed(2);

    // console.log("new subtotal", cartContext.subtotal);
  },
  updateGrandTotal: function(cartContext) {
    // console.log("updateGrandTotal");
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    cartContext.grandTotal =
      parseFloat(cartContext.subtotal) + parseFloat(cartContext.shippingFee);

    cartContext.grandTotal = cartContext.grandTotal.toFixed(2);

    // console.log("grandTotal", cartContext.grandTotal);
  },
  emptyCart: function(cartContext) {
    if (!cartContext) {
      console.error("cartContext is empty");
      return;
    }

    cartContext.items = [];
    cartContext.promoDiscount = zero;
    cartContext.promoCode = "";
    cartContext.subtotal = zero;
    cartContext.grandTotal = zero;

    this.update(cartContext);
  },
  generateOrderNumber: function() {
    let code =
      Math.random()
        .toString()
        .slice(2, 6) +
      "-" +
      Math.random()
        .toString()
        .slice(2, 6) +
      "-" +
      Math.random()
        .toString()
        .slice(2, 6);
    return (
      <span id="orderNumber" className="font-weight-bold">
        {code}
      </span>
    );
  }
};

export default CartUtil;
