import React, { createContext } from "react";

// For Testing
import ProductData from "../data/ProductData";
import CartUtil from "./CartUtil";

const zero = (0.0).toFixed(2);

export const CartContext = createContext({
  items: [],
  promoCode: "",
  promoDiscount: 0,
  subtotal: 0,
  shippingFee: 0,
  grandTotal: 0,
  fulfillmentType: "delivery",
});

export class CartProvider extends React.Component {
  testState = {
    items: [
      {
        quantity: 2,
        itemTotal: 1799.98,
        product: ProductData[1],
      },
      {
        quantity: 3,
        itemTotal: 2248.47,
        product: ProductData[2],
      },
    ],
    promoCode: "",
    promoDiscount: zero,
    subtotal: 4048.45,
    shippingFee: (10.0).toFixed(2),
    grandTotal: 2129.95,
  };

  emptyState = {
    items: [],
    promoCode: "",
    promoDiscount: zero,
    subtotal: zero,
    shippingFee: 9.99,
    grandTotal: zero,
  };

  state = CartUtil.fetchDatabase() || this.emptyState;

  render() {
    return (
      <CartContext.Provider value={this.state}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export const CartConsumer = CartContext.Consumer;
