import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { CartContext } from "../../state/CartContext";
import CartUtil from "../../state/CartUtil";

/**
 * GA expects to see this: 
 * https://support.google.com/tagmanager/answer/6107169
 * 
  {dataLayer.push({
  ‘transactionId’: ‘1234’,
  ‘transactionAffiliation’: ‘Acme Clothing’,
  ‘transactionTotal’: 38.26,
  ‘transactionTax’: 1.29,
  ‘transactionShipping’: 5,
  ‘transactionProducts’: [{
    ‘sku’: ‘DD44’,
    ‘name’: ‘T-Shirt’,
    ‘category’: ‘Apparel’,
    ‘price’: 11.99,
    ‘quantity’: 1
  },{
    ‘sku’: ‘AA1243544’,
    ‘name’: ‘Hat’,
    ‘category’: ‘Apparel’,
    ‘price’: 9.99,
    ‘quantity’: 2
  }]
});}
 */
const convertItemsToGoogleAnalyticsFormat = items => {
  let transactionProducts = [];

  transactionProducts = items.map(function(item) {
    return {
      sku: item.product.id,
      name: item.product.title,
      category: item.product.categories,
      price: item.itemTotal,
      quantity: item.quantity
    };
  });

  return transactionProducts;
};

// https://stackoverflow.com/a/2117523
const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const Confirmation = ({ history }) => {
  const cartContext = useContext(CartContext);
  const [amount] = useState(cartContext.grandTotal);
  const [shippingFee] = useState(cartContext.shippingFee);
  const [items] = useState(cartContext.items);
  const orderNumber = CartUtil.generateOrderNumber();

  if (amount === 0) {
    history.push("/checkout/cart");
  } else {
    CartUtil.emptyCart(cartContext);
  }

  const pageTitle = "Congratulations!";
  useEffect(() => {
    document.title = `${pageTitle}`;

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer = window.dataLayer.filter(function(el) {
      return !el.conversionValue;
    });

    window.dataLayer.push({
      ecommerce: {
        transactionId: uuidv4(),
        transactionTotal: `${amount}`,
        transactionShipping: `${shippingFee}`,
        transactionProducts: convertItemsToGoogleAnalyticsFormat(items)
      }
    });
  });

  return (
    <div id="confirmationPage" className="container">
      <div className="row">
        <div className="col">
          <h1>{pageTitle}</h1>
        </div>
      </div>
      <hr className="mb-4" />

      <div className="row">
        <div className="col">
          <p>
            Congratulations on your <span id="amount">${amount}</span> purchase.
            Your order number is <span id="orderNumber">{orderNumber}</span>.
          </p>
          <p>
            We know you'll love your new items to help you explore this
            beautiful world and look stylish.
          </p>
        </div>
      </div>

      <hr className="mb-8" />
      <div className="row">
        <div className="col">&nbsp;</div>
        <div className="col-sm-8">
          <Link to="/">
            <button
              id="cartContinueShoppingBtn"
              type="button"
              className="btn btn-primary btn-lg btn-block"
            >
              Continue Shopping
            </button>
          </Link>
        </div>
        <div className="col">&nbsp;</div>
      </div>
    </div>
  );
};

export default Confirmation;
