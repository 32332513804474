import React, { useEffect } from "react";

import logo from "../../images/logos/quinn-logo-big.png";

function InternalError() {
  const pageTitle = "500 - Internal Server Error";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });
  return (
    <div id="internalServerErrorPage" className="container">
      <div className="row">
        <div className="col">
          <h1>{pageTitle}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <img
            id="internalServerErrorImg"
            src={logo}
            className="img-fluid rounded"
            alt="Internal server error"
          />
        </div>
      </div>
    </div>
  );
}

export default InternalError;
