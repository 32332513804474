import React, { useEffect } from "react";

import PageTitle from "../PageTitle";
import LoginForm from "./LoginForm";
import ContinueAsGuestForm from "./ContinueAsGuestForm";

const LoginPage = () => {
  const pageTitle = "Your Account";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });

  return (
    <div>
      <PageTitle key={pageTitle} title={pageTitle} />
      <div className="row">
        <div className="col">
          <LoginForm />
        </div>
        <div className="col">
          <ContinueAsGuestForm />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
