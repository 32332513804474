import React from "react";
import { Link } from "react-router-dom";
const pjson = require("../../package.json");

const Footer = props => {
  const year = new Date().getFullYear();
  const version = pjson.version;

  return (
    <footer className="footer">
      <div className="container text-center align-middle text-muted">
        <small>&copy; Quinn &amp; Murray - {year}</small>
        &nbsp;|&nbsp;
        <small>
          <a
            id="qualtrics-link"
            href="https://qpartnerdev.co1.qualtrics.com/jfe/form/SV_8f99QEZXfzc0B9j?Q_SDID=SD_9QE5e1HbdpsWE1D"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feedback
          </a>
        </small>
        &nbsp;|&nbsp;
        <small className="text-reset">
          <Link id="customerServiceLink" to="/contact">
            Customer Service
          </Link>
        </small>
        &nbsp;|&nbsp;
        <small id="version" className="text-reset">
          {version}
        </small>
      </div>
    </footer>
  );
};

export default Footer;
