import React, { useContext, useEffect } from "react";

import { CartContext } from "../../state/CartContext";
import { Link } from "react-router-dom";

import PageTitle from "../PageTitle";
import TechnicalIssuesErrorMessage from "../error/TechnicalIssuesErrorMessage";
import CartItemList from "./CartItemList";
import PromoCodeSection from "./PromoCodeSection";
import PaypalButton from "./PaypalButton";

import CartUtil from "../../state/CartUtil";

const SubtotalText = () => {
  const cartContext = useContext(CartContext);
  return (
    <div className="row align-items-center">
      <div className="col-8">
        <div className="form-group">&nbsp;</div>
      </div>
      <div className="col-sm-2 text-right h6 align-items-center">Subtotal:</div>
      <div className="col-sm-2 align-items-center">
        <span id="subTotal" className="h6 font-weight-bold">
          ${cartContext.subtotal}
        </span>
      </div>
    </div>
  );
};

const CheckoutButton = props => {
  // console.log(props.active);
  return (
    <div className="row justify-content-end">
      <div className="col col-md-10 col-lg-10">&nbsp;</div>
      <div className="col col-md-2 col-lg-2">
        <Link to="/login/">
          {props.active ? (
            <button
              id="checkoutBtn"
              type="button"
              className="btn btn-primary btn-lg"
            >
              Checkout
            </button>
          ) : (
            <button
              id="checkoutBtn"
              type="button"
              className="btn btn-primary btn-lg"
              disabled
            >
              Checkout
            </button>
          )}
        </Link>
      </div>
    </div>
  );
};

const EmptyCart = () => {
  return (
    <div className="row">
      <div className="col text-center mt-5">
        <p>Your Cart is Empty</p>
        <p className="h5">
          <Link to="/catalog">
            <button
              id="startShoppingBtn"
              type="button"
              className="btn btn-success"
            >
              Start Shopping
            </button>
          </Link>
        </p>
      </div>
    </div>
  );
};

const NotEmptyCart = props => {
  const cartContext = useContext(CartContext);

  const handleEmptyCart = () => {
    CartUtil.emptyCart(cartContext);
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <CartItemList edit="yes" />

          <hr className="mb-4" />
          <PromoCodeSection />

          <SubtotalText amount={cartContext.subtotal} />

          <CheckoutButton active={!props.error} />
          <PaypalButton active={!props.error} />
        </div>
      </div>
      <div className="row mt-5 h5 justify-content-center">
        <div className="col text-center">
          <Link to="/checkout/cart">
            <button
              id="emptyCartBtn"
              type="button"
              className="btn btn-outline-danger"
              onClick={handleEmptyCart}
            >
              Empty Cart
            </button>
          </Link>
        </div>
        <div className="col text-center">
          <Link to="/catalog">
            <button
              id="continueShoppingBtn"
              type="button"
              className="btn btn-success"
            >
              Continue Shopping
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Cart = ({ history }) => {
  const cartContext = useContext(CartContext);
  // console.log("cartContext", cartContext);

  const [isError, setIsError] = React.useState(false);
  const visible = "d-block";
  const hidden = "d-none";
  const [cartErrorIsVisible, setCartErrorState] = React.useState(hidden);

  const pageTitle = "Shopping Cart";
  useEffect(() => {
    // console.log("useEffect in CartPage", cartContext);
    document.title = `${pageTitle}`;

    CartUtil.update(cartContext);

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer = window.dataLayer.filter(function(el) {
      return !el.cartValue;
    });

    window.dataLayer.push({
      cartValue: `${cartContext.subtotal}`
    });

    if (cartContext.subtotal > 5000) {
      setCartErrorState(visible);
      setIsError(true);
    }
  }, [cartContext]);

  return (
    <div id="cartPage" className="container">
      <PageTitle title={pageTitle} />

      <TechnicalIssuesErrorMessage visible={cartErrorIsVisible} />

      {!cartContext.items || cartContext.items.length === 0 ? (
        <EmptyCart />
      ) : (
        <NotEmptyCart error={isError} />
      )}
    </div>
  );
};

export default Cart;
