import React, { useEffect } from "react";

import PageTitle from "./PageTitle";
import rewardsImage from "../images/sugar.png";

const RewardsPage = () => {
  const pageTitle = "Rewards";
  useEffect(() => {
    document.title = `${pageTitle}`;
  });
  return (
    <div id="rewardsPage" className="container">
      <PageTitle title={pageTitle} />

      <div className="row justify-content-center">
        <div className="col-sm-10 col-md-8 col-lg-6 text-center">
          <img
            id="rewardsImage"
            src={rewardsImage}
            className="img-fluid rounded"
            alt="Rewards"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col text-center">
          <h2>A more refined way to be sweetly rewarded.</h2>
          <p>
            Our members save an average of $500 per year with their points. You
            can too!
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col text-center">
          <button id="rewardsJoinButton" type="button" className="btn btn-info">
            Join Now!
          </button>
        </div>
      </div>
    </div>
  );
};

export default RewardsPage;
