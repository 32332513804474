const checkoutNameLocalStorage = "checkout";

const nullState = {
  email: null,
  shippingInfo: {
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: null
  },
  billingInfo: {
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: null
  },
  paymentInfo: {
    name: null,
    number: null,
    expiration: null,
    cvv: null
  }
};

const CheckoutUtil = {
  update: function(context) {
    // console.log("context: ", context);
    this.updateDatabase(context);
  },
  empty: function(context) {
    context = nullState;

    this.update(context);
  },
  updateDatabase: function(context) {
    localStorage.setItem(checkoutNameLocalStorage, JSON.stringify(context));
  },
  fetchDatabase: function() {
    const cart = localStorage.getItem(checkoutNameLocalStorage);
    return JSON.parse(cart);
  }
};

export default CheckoutUtil;
